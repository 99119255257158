import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { pdfSizeLimit, photosSizeLimit, allowExtension } from 'src/app/config/constants/app.constants';
import { DynamicFormService } from 'src/app/config/core/dynamic-form/dynamic-form.service';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MediaCollectionService } from 'src/app/services/media-collection/media-collection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'xa-inline-document-uploader',
  templateUrl: './xa-inline-document-uploader.component.html',
  styleUrls: [ './xa-inline-document-uploader.component.scss' ]
})
export class XaInlineDocumentUploaderComponent implements OnInit, OnChanges {
  @Input() objectId: any;
  @Input() domainId: any;
  @Input() existingDocumentConfig;
  @Input() existingDocs: any = [];

  public mediaCollectionGuid: any;
  public mediaData: any = [];
  public existingDocumentsOptions = [];
  public additionalPartMediaObj = null;
  public additionalMediaObj = null;
  public mediaId: any;

  @Output() documentsModified: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeMediaPart: EventEmitter<any> = new EventEmitter<any>();

  public uploader: ElementRef;
  /**
    Sets the content of the uploader element.
    @param {ElementRef} content - The content of the uploader element.
  */
  @ViewChild('uploader') set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.uploader = content;
    }
  }

  public file: any[] = [];


  /**
   * constructor
   * @param fb 
   */
  constructor(
    private readonly dynamicFormService: DynamicFormService,
    public commonService: CommonService,
    private readonly monitorService: MonitorService,
    private readonly dialog: MatDialog,
    private translateService: TranslateService,
    private mediaCollectionService: MediaCollectionService
  ) {

  }
  /**
 * handle file upload
*/
  resetAdditionalMediaPart(): void {
    // Reset the additionalPartMediaDoc to next part
    this.additionalPartMediaObj = this.additionalMediaObj?.mediaParts?.find((partMedia) => {
      return !partMedia.getURL && this.file.map(f => f.mediaPartTemplateGuid).indexOf(partMedia.mediaPartTemplateGuid) === -1
    })
  }

  /**
 * checks file size
 * @param blob 
 * @returns boolean
 */
  checkFileSize(img: Blob): boolean {
    const sizeLimit = this.additionalPartMediaObj?.sizeLimit ? this.additionalPartMediaObj?.sizeLimit : img.type === 'application/pdf' ? pdfSizeLimit : photosSizeLimit;
    this.monitorService.logEvent('checkFileSize', [ 'PaymentAddComponent', 'addenda-claim', {
      fileSize: img.size
    } ]);
    if (img.size > sizeLimit) {
      return false;
    }
    return true;
  }

  /**
* check filename
* @param filename 
* @returns 
*/
  public isValidImageFile(filename: string): boolean {
    const regex: RegExp = new RegExp('^.*\\.[a-zA-Z]+$', 'gm');
    this.monitorService.logEvent('isValidImageFile', [ 'PaymentAddComponent', 'addenda-repair', {
      fileName: filename
    } ]);
    if (regex.test(filename)) {
      const extension = filename.split('.').pop();
      const fileExtension = this.additionalPartMediaObj?.allowExtension ? this.additionalPartMediaObj?.allowExtension : allowExtension;
      return fileExtension.includes(extension?.toLowerCase());
    }
    return false;
  }

  /**
 * 
 * @param url 
 */
  download(document): void {
    let documentUrl = '';
    if (document.url) {
      documentUrl = document.url;
      this.commonService.download(documentUrl);
    } else if (document) {
      const blob = window.URL.createObjectURL(document.file);
      const link = window.document.createElement('a');
      link.href = blob;
      link.download = decodeURIComponent(document.name);

      // Version link.click() to work at firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      setTimeout(() => {
        // firefox
        window.URL.revokeObjectURL(blob);
        link.remove();
      }, 100);
    }
  }

  /**
 * selection
 */
  selection(event): void {
    const alreadyExists = this.file.find(ele => ele.name === event.option.name)
    if (!alreadyExists) {
      if(this.existingDocs?.length > 0){
        const alreadySelected = this.existingDocs.find(ele => ele.Name?.toLowerCase() === event.option.name?.toLowerCase());
        if(!alreadySelected){
          this.file.push({
            ...event.option
          })
        }
        return;
      }
      this.file.push({
        ...event.option
      })
    }
  }

  /**
Handles the click event for the "Add File" button.
@param {Event} event - The click event.
@returns {void}
*/
  handleAddFileClick(): void {
    this.uploader.nativeElement.click()
  }

  /**
  Removes a file from the component's state.
  @param {any} event - The event object that triggered the function.
  @returns {void}
*/
  handleFileRemove(index: any, doc?: any): void {
    this.commonService.openDeleteDialog(
      '',
      this.translateService.instant('attachment_delete_confirmation'),
      this.translateService.instant('delete'),
      this.translateService.instant('cancel')
    )
      .afterClosed().subscribe((data) => {
        if (data) {
          if(!doc) {
            this.monitorService.logEvent('handleFileRemove', [ 'RecoveryFormComponent', 'addenda-claim' ]);
            this.file.splice(index, 1);
            this.resetAdditionalMediaPart();
            this.documentsModified.emit(this.file);
          } else {
            this.removeMediaPart.emit(doc)
          }
          
        }
      });

  }

  /**
 * 
 * downloadMediaPart 
*/
  downloadMediaPart(document): void {
    if(document.BlobUrl){
      this.commonService.download(document.BlobUrl);
    }else
      if (document.MediaGuid && document.MediaPartGuid) {
        this.commonService.showLoading()
        this.mediaCollectionService
          .getMediaPartDownloadUrl(document.MediaGuid, document.MediaPartGuid, this.objectId, this.domainId)
          .subscribe({
            next: (res) => {
              this.commonService.hideLoading();
              if(res?.data?.mediaParts?.[0]?.getURL) {
                this.commonService.download(res?.data?.mediaParts?.[0]?.getURL)
              }
            },
            error: () => {
              this.commonService.hideLoading();
            }
          })
      }
  }
  /**
 * handle file upload
 * @param event 
*/
  handleUpload(event: any): void {
    const currentFile = event.target.files || event.srcElement.files;
    this.monitorService.logEvent('handleUpload', [ 'RecoveryFormComponent', 'addenda-claim', {
      event
    } ]);
    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      if (!this.checkFileSize(currentFile[0])) {
        const message = this.translateService.instant('file_size_5mb_error_message');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      if (!this.isValidImageFile(currentFile[0].name)) {
        const message = this.translateService.instant('upload_type_not_supported');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(currentFile[0]);
      reader.onload = (): void => {
        this.file.push({
          file: currentFile[0],
          name: this.additionalPartMediaObj.name,
          mediaGuid: this.additionalMediaObj.mediaGuid,
          mediaPartGuid: this.additionalPartMediaObj.mediaPartGuid,
          mediaPartTemplateGuid: this.additionalPartMediaObj.mediaPartTemplateGuid,
          mediaTemplateGuid: this.additionalMediaObj.mediaTemplateGuid
        });
        this.resetAdditionalMediaPart();
        this.documentsModified.emit(this.file);
      }
    }
  }

  /**
Handles the click event for the "Add File" button.
@returns {void}
*/
  getMediaCollectionData(): void {
    this.commonService.showLoading()
    this.mediaCollectionService.getMediaTemplate().subscribe({
      next: (response) => {
        this.commonService.hideLoading()
        if (response && response.data?.length > 0) {
          this.mediaId = response.data[0].id;
          this.mediaCollectionGuid = response.data[0].collectionGuid;
          this.getMediaData()
        }
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * Retrieves the media data for the given mediaId and objectId.
   * Shows a loading indicator while the data is being retrieved.
   * Once the data is retrieved, it hides the loading indicator.
   * If the response contains media data, it will populate the
   * existingDocumentsOptions and additionalMediaObj variables.
   * existingDocumentsOptions is an array of document options that
   * already have a url associated with them. additionalMediaObj is
   * the media object that is associated with the 'Additional Documents'
   * media template.
   * @returns {void}
   */
  getMediaData(): void {
    if (this.mediaId && this.objectId) {
      this.commonService.showLoading()
      this.mediaCollectionService.getMediaDocumentData(this.mediaId, this.objectId, this.domainId).subscribe({ // make domain id dynamic
        next: (response) => {
          this.commonService.hideLoading();
          if (response?.data?.medias) {
            this.mediaData = response.data.medias;
            const existingOptions = []
            response.data.medias?.forEach((media) => {
              if (media.name.toLowerCase() !== 'additional documents') {
                media.mediaParts?.forEach((mediaPart) => {
                  if (mediaPart?.getURL) {
                    existingOptions.push({
                      name: mediaPart.name,
                      url: mediaPart.getURL,
                      mediaGuid: media.mediaGuid,
                      mediaPartGuid: mediaPart.mediaPartGuid
                    })
                  }
                })
              }
            });
            this.existingDocumentsOptions = [ ...existingOptions ]
            const additionalDoc = this.mediaData?.find(media => media.name.toLowerCase() === 'additional documents')
            if (additionalDoc) {
              this.additionalMediaObj = additionalDoc;
              const additionalMedia = additionalDoc.mediaParts.find(ele => !ele.getURL)
              if (additionalMedia) {
                additionalMedia.mediaGuid = additionalDoc.mediaGuid
                this.additionalPartMediaObj = additionalMedia
              }
            }
          }
        },
        error: () => {
          this.commonService.hideLoading();
        }
      });
    }
  }

  /**
     * ng on init
     */
  ngOnInit(): void {
    this.getMediaCollectionData()
  }

  /**
      Lifecycle hook that is called when any data-bound property of a directive changes.
      @param {SimpleChanges} changes - An object containing the changed properties and their current and previous values.
    */
  ngOnChanges(changes: SimpleChanges): void {
    this.monitorService.logEvent('ngOnChanges', [ 'XaInlineDocumentUploaderComponent', 'addenda-claims' ]);
    if (changes) {
      if (changes['objectId'] && changes['objectId'].currentValue) {
        this.mediaData = [];
        this.existingDocumentsOptions = [];
        this.additionalPartMediaObj = null;
        this.additionalMediaObj = null;
        this.file = []
        this.getMediaData()
      }
    }
  }
}
