import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common/common.service';
import { MediaCollectionService } from 'src/app/services/media-collection/media-collection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { MessagesService } from 'src/app/services/message/message.service';
import * as dayjs from 'dayjs';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';

@Component({
  selector: 'email-remainder-dialog',
  templateUrl: './email-remainder.component.html',
  styleUrls: [ './email-remainder.component.scss' ]
})
export class EmailRemainderComponent implements OnInit {

  estimateDocumentTypes:any[] = [];
  mediaData:any;
  comMediaDetail:any[] = [];
  comInspectionDetail:any;
  mediaCollectionId:number;
  mediaTemplateId:number;
  mediaPartTemplateId:number;
  isAdditionalImage = false;
  recoveryDetail:any;
  mediaFiles:any;
  claimantDetails:any;
  emailTemplateDetail;
  selectedDocuments:any[] = [];
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  
  public availableDocumentsSlot = 0;
  public availablePhotosSlot = 0;
  
  readonly maxFileCount = 20;
  readonly maxBulkPhotosCount = 20;
  readonly maxSPIPhotosCount = 12;
  private currentFileCount = 0;
  public documents:any[] = [];
  
  // 30 MB file size limit
  readonly maxTotalFileSizeLimit = 31457280;
  private currentTotalFileSize = 0;
  public email:string;
  
  private readonly allowedPhotoType = [ 'image/jpg', 'image/jpeg', 'image/png' ];
  private readonly allowedDocumentType = [ 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf' ];
  public step:any = {};

  public emailRequest:any = {
    subject: '',
    email: [],
    message: '',
    phone: '',
    code: ''
  }

  public existingDocumentConfig: XADropdownConfig = {
    name: 'existingDocument',
    label: 'Select Document',
    placeHolder: 'Select from existing',
    labelKey: 'name',
    valueKey: 'url',
    multiple: true
  }
  
  selectedCode: string = '';
  messageTemplate:any;
  additionalPhotos:any[] = [];
  additionalDocs:any[] = [];
  matchinginspectionItems: any[] = [];
  matchingMediaItems: any[] = [];
  bulkTemplateId:number;
  private userProfile:any;
  private additionalDoc:any;
  public isLoading:boolean;

  private readonly uploadSizeLimit = 10485760;
  public uploadDoc: ElementRef;
    /**
      Sets the content of the uploader element.
      @param {ElementRef} content - The content of the uploader element.
    */
    @ViewChild('uploadDoc') set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.uploadDoc = content;
    }
  }
    
    /**
   * constructor
   * @param dialogRef
   * @param data
   */
    constructor(
    public dialogRef: MatDialogRef<EmailRemainderComponent>,
    private monitorService: MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private commonService: CommonService,
    private mediaCollectionService:MediaCollectionService,
    private messageService:MessagesService
    ) {
      this.commonService.userProfileData.subscribe({
        next: (res: any) => {
          if (res) {
            this.userProfile = res?.data;
          }
        }
      });
    }

    /**
   * ng oninit
   */
    ngOnInit(): void {
      this.commonService.showLoading();
      this.getMessageTemplateForRemainder();
    }

    /**
   * get message template
   */
    getMessageTemplateForRemainder():void{
      this.isLoading = true;
      this.messageService.getRemainderMessageTemplate(this.data.claimGuid, this.data.domainId, this.data.collectionId, this.data.objectId, this.data.templateKey, this.data.recoveryGuid).subscribe((data: any) => {
        this.messageTemplate = data;
        if(this.messageTemplate?.length > 0) {
          this.claimantDetails = this.messageTemplate[0];
          this.emailTemplateDetail = this.messageTemplate[1]?.data;
          this.recoveryDetail = this.messageTemplate[2]?.value[0];
          this.mediaFiles = this.messageTemplate[3].data.medias;
          this.email = this.recoveryDetail?.email || '';
          const existingOptions = [];
          this.mediaFiles?.forEach((media) => {
            media?.mediaParts?.forEach((mediaPart) => {
              if(mediaPart?.getURL) {
                existingOptions.push({
                  name: mediaPart.name,
                  url: mediaPart.getURL,
                  mediaGuid: media.mediaGuid,
                  fileSize: mediaPart.fileSize,
                  fileName: mediaPart.fileName,
                  mediaPartGuid: mediaPart.mediaPartGuid
                })
              }
            })
          });
          const recoveryReceipts = this.recoveryDetail?.DocumentDetails;
          const estimateReports = this.recoveryDetail?.DocumentDetails.filter(x=>!x.MediaPartGuid)?.map((x)=>{
            return{
              name: x.Name,
              url: x.BlobUrl
            }
          });
          const receiptGUIDs = recoveryReceipts?.map((r: any) => r.MediaPartGuid?.toLowerCase());

          this.additionalDoc = this.mediaFiles?.find(media => media.name.toLowerCase() === 'additional documents')
          this.matchingMediaItems = this.additionalDoc?.mediaParts.filter(obj=>obj.fileSize === 0);

          // Set Available slots for Documents and Photos
          this.availableDocumentsSlot = this.matchingMediaItems?.length;
          this.documents = [ ...existingOptions, ...estimateReports ];
          const selectedDocumentList = this.documents.filter(doc => receiptGUIDs.includes(doc.mediaPartGuid?.toLowerCase()));
          if(selectedDocumentList.length > 0){
            this.selectedDocuments = [ ...selectedDocumentList.map(doc => doc.url), ...estimateReports.map(e=>e.blobUrl) ];  

          }
        }
        this.isLoading = false;
        this.commonService.hideLoading();
     
      }, (err: any) => {
        this.isLoading = false;
        this.commonService.hideLoading();
      })
    }

    /**
   * send email
   */
    async sendEmail():Promise<any>{
      let documentAttachments = [];
      const uploadedDoc:any[] =this.additionalDocs.length > 0 ? await this.uploadDocs(this.additionalDocs): [];
      const selectedDocumentList = this.documents.filter(doc => this.selectedDocuments.includes(doc.url));
      if(uploadedDoc.length > 0) {
        documentAttachments = uploadedDoc.map((el, index)=> {
          return { blobUrl: el.blobUrl, name: el.name + index }
        });
      }
      let selectedAttachments = [];
      if(selectedDocumentList?.length > 0) {
        selectedAttachments = selectedDocumentList.map((el, index)=> {
          return { blobUrl: el.url, name: el.name + index }
        })
      }
      this.commonService.showLoading();
      const obj = {
        'toEmails': [ this.email ],
        'communicationTypeIds': [ 1 ],
        'templateKey': this.data.templateKey,
        'attachments': [
          ...documentAttachments, ...selectedAttachments 
        ]
      };
      this.messageService.sendRemainderMessage(obj, this.data.claimGuid, this.data.recoveryGuid).subscribe({
        next: (data) => {
          this.dialogRef.close();
          this.commonService.hideLoading();
        }, error: ()=>{
          this.commonService.hideLoading();
        }
      })

    }

    /**
   * get files
   */
    get files():any[]{
      if(!this.selectedDocuments || this.selectedDocuments?.length === 0) {
        return [];
      }
      return this.documents.filter(doc => this.selectedDocuments.includes(doc.url));
    }

    /**
   * File Validations
   */
    isAttachmentValid():boolean {
      this.currentFileCount = 0;
      this.currentTotalFileSize = 0;

      this.currentFileCount = this.documents.length + this.additionalPhotos.length + this.additionalDocs.length
      this.files?.forEach((el)=> {
        this.currentTotalFileSize += el.fileSize;
      })
      this.additionalDocs.forEach((el)=> {
        this.currentTotalFileSize += el.size;
      })

      if(this.currentFileCount > this.maxFileCount) {
        this.commonService.openConfirmationSingleOkDialog('Oops! You exceeded the total maximum file count', 'Please remove some files to continue adding more attachments');
      }
      if(this.currentTotalFileSize > this.maxTotalFileSizeLimit) {
        this.commonService.openConfirmationSingleOkDialog('Oops! You exceeded the total maximum file size', 'Please remove some files to continue adding more attachments');
      }
      return this.currentFileCount <= this.maxFileCount && this.currentTotalFileSize <= this.maxTotalFileSizeLimit
    }

    /**
   * Remove file
   */
    removeFile(index:number, type:string):void{
      if(type === 'document') {
        this.selectedDocuments.splice(index, 1);
        this.selectedDocuments = [ ...this.selectedDocuments ];
      }
      if(type === 'additionalDocs') {
        this.additionalDocs.splice(index, 1)
      }
      this.isAttachmentValid();
    }
    /**
   * upload Docs
   */
    uploadDocs(files:any): Promise<any> {
      let uploadCount = 0;
      this.commonService.showLoading();
      return new Promise((resolve, reject)=> {
        if(files?.length > 0) {
          const uploadedFiles:any[] = [];
          for (let i = 0; i < files?.length; i++) {
            const obj = {
              'mediaCollectionId': this.data.mediaCollectionGuid,
              'domainId': 4100,
              'objectId': this.data.objectId,
              'mediaTemplateId': this.additionalDoc?.mediaTemplateGuid,
              'mediaPartTemplateId': this.matchingMediaItems[i]?.mediaPartTemplateGuid,
            }
            this.mediaCollectionService.uploadDocument(files[i], obj).subscribe({ next: (res)=> {
              uploadedFiles.push({ name: `${files[i].name}_${new Date().getTime()}`, blobUrl: res.data.blobUrl, imageSize: files[i].size });
              uploadCount++;
              if(uploadCount === files.length) {
                resolve(uploadedFiles);
              }
            }, error: (err)=> {
              reject(err);
            } })
          }
        }
      })
    }

    /**
   * replace template string handler generic
   */
    get handleReplacementTags():string {
      let stringToReplace = this.emailTemplateDetail?.body;
      const replacementTags = stringToReplace?.match(/{{(.*?)}}/g);
      const propertyMapper = {
        'claimantDetail': [ 'claimno' ],
        'lossDetails': [ 'accidentdate' ],
        'customerDetails': [ 'claimantdetail', 'customername' ],
        'beneficiary': [ 'contactname', 'amount' ],
        'user': [ 'claimmanagername', 'companyname', 'position', 'insurancecompany', 'phoneno' ]
      }
      if(replacementTags?.length > 0) {
        for(const tag of replacementTags) {
          const tagValue = tag.replace('{{', '').replace('}}', '');
          if(propertyMapper.claimantDetail.includes(tagValue)) {
            stringToReplace = stringToReplace.replace(tag, this.claimantDetails.claimNo);
          }else if(propertyMapper.lossDetails.includes(tagValue)){
            stringToReplace = stringToReplace.replace(tag, dayjs(this.claimantDetails.lossDetails.lossDate).format('DD/MM/YYYY'));
          }else if(propertyMapper.customerDetails.includes(tagValue)){
            if(tagValue == 'customername') {
              stringToReplace = stringToReplace.replace(tag, `${this.claimantDetails.customerDetails.policyHolderFirstName} ${this.claimantDetails.customerDetails.policyHolderLastName}`);
            
            }else{
              stringToReplace = stringToReplace.replace(tag, `${this.claimantDetails.customerDetails.policyHolderFirstName} ${this.claimantDetails.customerDetails.policyHolderLastName}/${this.claimantDetails?.vehicleMetaData?.vehicleName}`);
            }
          
          }else if(propertyMapper.beneficiary.includes(tagValue)){
            if(tagValue == 'claimmanagername') {
              stringToReplace = stringToReplace.replace(tag, this.recoveryDetail.BeneficiaryName);
            }else if(tagValue == 'amount') {
              stringToReplace = stringToReplace.replace(tag, this.recoveryDetail.Amount);
            }else{
              stringToReplace = stringToReplace.replace(tag, this.recoveryDetail.BeneficiaryName);
            }
          }else if(propertyMapper.user.includes(tagValue)){
            if(tagValue == 'claimmanagername') {
              stringToReplace = stringToReplace.replace(tag, `${this.userProfile.firstName} ${this.userProfile.lastName}`);
            }else if(tagValue == 'position') {
              stringToReplace = stringToReplace.replace(tag, this.userProfile.roleDetail[0]?.name);
            }else if(tagValue ==='insurancecompany'){
              stringToReplace = stringToReplace.replace(tag, this.userProfile.organizationDetail?.name);
            }else if(tagValue ==='companyname'){
              stringToReplace = stringToReplace.replace(tag, this.claimantDetails?.organizationDetail?.nameOfOrganization);
            }else if(tagValue ==='phoneno'){
              stringToReplace = stringToReplace.replace(tag, `${this.userProfile.organizationDetail?.countryCode}${this.userProfile.organizationDetail?.phoneNumber}`);
            }
          }
        }
      }
      return stringToReplace;
    }


    /**
   * handleUpload
   */
    handleUpload(event:any, type:string):void {
      const currentFile = event.target.files || event.srcElement.files || event.dataTransfer.files;
      if(currentFile.length === 0) {
        return;
      }
      // Check Size limit;
      if(currentFile[0]?.size > this.uploadSizeLimit) {
        this.commonService.openConfirmationSingleOkDialog('Oops! You exceeded the total maximum file size', 'Please remove some files to continue adding more attachments');
        return;
      }

      if(type === 'document' && currentFile?.length > 0) {
      // check if still emppty
        if(!this.matchingMediaItems) {
          this.commonService.openConfirmationSingleOkDialog('Oops! Maximum limit reached for additional document attachments', 'Please remove some files to continue adding more attachments');
          return;
        }
        if(this.additionalDocs.length >= this.availableDocumentsSlot) {
          this.commonService.openConfirmationSingleOkDialog('Oops! Maximum limit reached for additional document attachments', 'Please remove some files to continue adding more attachments');
          return;
        }
        if(!this.allowedDocumentType.includes(currentFile[0].type)) {
          this.commonService.openConfirmationSingleOkDialog('Oops! Invalid file type', 'Please select a JPG, JPEG, PNG, or PDF file.');
          return;
        }
        this.additionalDocs.push(...currentFile)
        if(!this.isAttachmentValid()){
          this.additionalDocs.splice(-1, 1);
        }
      }
      if(this.uploadDoc){
        this.uploadDoc.nativeElement.value = null;
      }

    }

    /**
   * close dialog
   */
    close(): void {
      this.dialogRef.close(true);
      this.monitorService.logEvent('close', [ 'ConfirmDialogComponent', 'addenda-quote' ]);
    }

}
