import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { CommonService } from 'src/app/services/common/common.service';
import { InvoiceService } from 'src/app/services/invoice/invoice.service';
import { PaymentAddComponent } from './payment-add/payment-add.component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: [ './payments.component.scss' ]
})

export class PaymentsComponent {
  @ViewChild('paymentList', { static: true }) paymentList: PaymentListComponent;
  public closeButtonModel: ChqWidgetsButtonModel = {
    label: 'close',
    type: 'primary',
    icon: '',
  }

  @ViewChild('paymentadd', { static: false }) paymentadd:PaymentAddComponent;


  /**
    Constructor for the PaymentsComponent.
    @constructor
    @param {any} data - Data passed to the component.
    @param {MatDialogRef} dialogRef - Reference to the dialog box.
    @param {MonitorService} monitorService - Service for monitoring operations.
  */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentsComponent>,
    public commonService:CommonService,
    private invoiceService:InvoiceService,
    public monitorService: MonitorService
  ) {}

  /**
    Lifecycle hook that is called after the component is initialized.
  */
  ngOnInit(): void {
    if(this.data.dueAmount === this.data.invoiceAmount){
      this.data = { ...this.data, paymentStatus: 'unpaid' };
    }else
      if(this.data.dueAmount === 0){
        this.data = { ...this.data, paymentStatus: 'paid' };
      }else{
        this.data = { ...this.data, paymentStatus: 'partialpaid' };
      }
    this.monitorService.logEvent('ngOnInit', [ 'PaymentsComponent', 'addenda-repair' ]);
  }

  /**
   * get all payment
   */
  getInvoiceDetail():void{
    this.invoiceService.invoicePerDetailProvider(this.data.repairId, this.data.isWorkProvider? 1 : 2).subscribe({
      next: (resp)=>{
        const data = resp?.data;
        this.data = { ...this.data, ...data };
        setTimeout(()=>{
          this.paymentadd.updateValidation(this.data.dueAmount, this.data.paymentStatus);
        })
      }
    });
  }

  /**
    Closes the dialog with the given data.
    @param {any} data - The data to be passed back to the dialog caller.
  */
  cancelDialog(data: any): void {
    this.dialogRef.close(this.data.dueAmount);
  }

  /**
    Adds a payment to the payment list.
    @returns {void}
  */
  handleAddPayment(newData): void {
    this.paymentList.getPayments(this.data.invoiceGuid);
    this.data.dueAmount = ((this.data.dueAmount * 100000) - (newData.Amount * 100000))/100000


    if(this.data.dueAmount === this.data.invoiceAmount){
      this.data = { ...this.data, paymentStatus: 'unpaid' };
    }else
      if(this.data.dueAmount === 0){
        this.data = { ...this.data, paymentStatus: 'paid' };
      }else{
        this.data = { ...this.data, paymentStatus: 'partialpaid' };
      }
    this.getInvoiceDetail();
  }
  /**
    delete a payment to the payment list.
    @returns {void}
  */  
  handlePaymentDelete(newData): void {
    if(newData?.Amount) {
      this.data.dueAmount = ((this.data.dueAmount * 100000) + (parseFloat(newData.Amount) * 100000))/ 100000
    }

    if(this.data.dueAmount === this.data.invoiceAmount){
      this.data = { ...this.data, paymentStatus: 'unpaid' };
    }else
      if(this.data.dueAmount === 0){
        this.data = { ...this.data, paymentStatus: 'paid' };
      }else{
        this.data = { ...this.data, paymentStatus: 'partialpaid' };
      }
    this.getInvoiceDetail();
  }
}
