import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection!: signalR.HubConnection;
  private repairHubConnection!: signalR.HubConnection;
  private bookingHubConnection!: signalR.HubConnection;
  private autoCoreHubConnection!: signalR.HubConnection;
  hubReceivedMessage: BehaviorSubject<any>;
  repairHubReceivedMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  bookingHubReceivedMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  autoCoreHubReceivedMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userNotificationKey = ''
  orgId: number = 0;
  /**
   * constructor
   */
  constructor(private readonly commonService: CommonService) {
    this.hubReceivedMessage = new BehaviorSubject<any>(null);
    if (this.commonService.repairPermission || this.commonService.claimPermission) {
      this.commonService.userProfileData.subscribe((data) => {
        const services = [ 'addenda repair', 'addenda claims' ];

        const automotiveService = data.data.roleDetail.find(el => services.includes(el.automotiveServiceName.toLowerCase()));
        const automotiveServiceId = automotiveService?.automotiveServiceId;
        const tenantInfoKey = Object.keys(data.data.userPermission).find(item => item === 'tenantInfo');
        this.orgId = tenantInfoKey ? data.data.userPermission[tenantInfoKey].orgID : 0;

        this.userNotificationKey = `addendauser_${automotiveServiceId}_${data.data.id}`
        this.initiateSignalRConnection();

        if (automotiveService.automotiveServiceName?.toLowerCase() !== 'addenda claims') {
          this.initCaseSignalrConnection();
          this.initBookingSignalrConnection();
          this.initAutoCoreSignalrConnection();
        }
      })
    }
  }

  /**
   * subscribeToNotification
   */
  private subscribeToNotification(): void {
    this.hubConnection.invoke(environment.signalR.subscribeMethodName, this.userNotificationKey)
  }

  /**
   * setSignalRClientMethods
   */
  private initiateSignalRConnection(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Debug)
        .withUrl(environment.signalR.notifyUrl, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          headers: { 'Ocp-Apim-Subscription-Key': environment.subscriptionKey },
          accessTokenFactory: () => {
            return lastValueFrom(this.commonService.getAccessToken())
          },
        }) // the SignalR server url
        .withAutomaticReconnect()
        .build();

      this.setSignalRClientMethods();
      this.hubConnection
        .start()
        .then(() => {
          this.subscribeToNotification();
          resolve(true);
        })
        .catch((error) => {
          console.log('Websocket Error:', error)
          reject(false);
        });
    });
  }
  /**
   * initiate Case-management SignalrConnection
   */
  private initCaseSignalrConnection(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.repairHubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Debug)
        .withUrl(environment.signalR.repairNotifyUrl, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          headers: { 'Ocp-Apim-Subscription-Key': environment.subscriptionKey },
          accessTokenFactory: () => {
            return lastValueFrom(this.commonService.getAccessToken())
          },
        }) // the SignalR server url
        .withAutomaticReconnect()
        .build();

      this.repairHubConnection
        .start()
        .then(() => {
          this.setRepairListener();
          resolve(true);
        })
        .catch((error) => {
          console.log('Websocket Error:', error)
          reject(false);
        });
    });
  }

  /**
   * initiate Booking-management SignalrConnection
   */
  private initAutoCoreSignalrConnection(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.autoCoreHubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Debug)
        .withUrl(environment.autoCoreApi + '/notify', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          headers: { 'Ocp-Apim-Subscription-Key': environment.subscriptionKey },
          accessTokenFactory: () => {
            return lastValueFrom(this.commonService.getAccessToken())
          },
        }) // the SignalR server url
        .withAutomaticReconnect()
        .build();

      this.autoCoreHubConnection
        .start()
        .then(() => {
          this.setAutoCoreListener();
          resolve(true);
        })
        .catch((error) => {
          console.log('Websocket Error:', error)
          reject(false);
        });
    });
  }

  /**
   * initiate Booking-management SignalrConnection
   */
  private initBookingSignalrConnection(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.bookingHubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Debug)
        .withUrl(environment.bookingMgmtUrl + '/notify', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          headers: { 'Ocp-Apim-Subscription-Key': environment.subscriptionKey },
          accessTokenFactory: () => {
            return lastValueFrom(this.commonService.getAccessToken())
          },
        }) // the SignalR server url
        .withAutomaticReconnect()
        .build();

      this.bookingHubConnection
        .start()
        .then(() => {
          this.setBookingListener();
          resolve(true);
        })
        .catch((error) => {
          console.log('Websocket Error:', error)
          reject(false);
        });
    });
  }

  /**
   * 
   * @param orgId 
   * @param currentUserOrgId 
   * @returns 
   */
  checkOrgId(orgId: number | number[], currentUserOrgId: number): boolean {
    if (Array.isArray(orgId)) {
      return orgId.filter(x => x !== 0).includes(currentUserOrgId);
    }
    return orgId === currentUserOrgId;
  }


  /**
   * setRepairListener
   */
  private setRepairListener(): void {
    this.repairHubConnection.on(environment.signalR.repairListenerMethodName, (data: any) => {
      if (typeof data === 'object' && data !== null && Object.keys(data).length > 0 && this.checkOrgId(data.orgId, this.orgId)) {
        this.repairHubReceivedMessage.next(data);
      }
    });
  }
  /**
   * setRepairListener
   */
  private setBookingListener(): void {
    this.bookingHubConnection.on(environment.signalR.bookingListenerMethodName, (data: any) => {
      if (typeof data === 'object' && data !== null && Object.keys(data).length > 0 && this.checkOrgId(data.orgId, this.orgId)) {
        this.bookingHubReceivedMessage.next(data);
      }
    });
  }

  /**
 * setRepairListener
 */
  private setAutoCoreListener(): void {
    this.autoCoreHubConnection.on(environment.signalR.repairListenerMethodName, (data: any) => {
      if (typeof data === 'object' && data !== null && Object.keys(data).length > 0) {
        this.autoCoreHubReceivedMessage.next(data);
      }
    });
  }

  /**
   * setSignalRClientMethods
   */
  private setSignalRClientMethods(): void {
    this.hubConnection.on(environment.signalR.listenerMethodNameForUser, (data: any) => {
      this.hubReceivedMessage.next(data);
    });
  }


  /**
   * stopSignalrConnection
   */
  public stopSignalrConnection(): void {
    this.hubConnection?.stop()
      .then(() => {
        // then block
      })
      .catch((error) => {
        // error
      })
  }

  /**
   * stopRepairConnection
   */
  public stopRepairConnection(): void {
    this.repairHubConnection?.stop()
      .then(() => {
        // then block
      })
      .catch((error) => {
        // error
      })
  }
}
