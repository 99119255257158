<div class="add-photo-container">
    <div class="photo-container"
         *ngIf="(uploadModeSelector.showSPI || uploadModeSelector.showMultipleUpload) && !uploadModeSelector.isDisabled">
        <label>{{uploadModeSelector.label}}</label>
        <div class="card-container">
            <div class="card"
                 *ngIf="uploadModeSelector.showSPI">
                <div>
                    <label class="card-title">{{ (uploadModeSelector?.spiLabel || 'smart_photo_inspection') | translate }}</label>
                    <p class="card-action">{{ 'open_your_camera' | translate }}</p>
                    <p class="card-description">
                        {{ (uploadModeSelector?.spiDescription || 'add_photo_msg') | translate }}
                    </p>
                </div>

                <xa-button (click)="allowSingleUpload();">
                    <mat-icon svgIcon="camera"
                              aria-hidden="false"
                              aria-label="unchecked icon">
                    </mat-icon>
                    <span>{{'camera_upload' | translate}}</span>
                </xa-button>
            </div>
            <div class="card"
                 *ngIf="uploadModeSelector.showMultipleUpload">
                <label class="card-title">{{ (uploadModeSelector?.bulkLabel || 'bulk_upload') | translate }}</label>
                <p class="card-action">{{ 'upload_device' | translate }}</p>
                <p class="card-description">
                    {{ (uploadModeSelector?.bulkDescription || 'bulk_msg') | translate }}
                </p>
                <xa-button (click)="allowMultipleUpload();">
                    <mat-icon svgIcon="upload"
                              aria-hidden="false"
                              aria-label="unchecked icon">
                    </mat-icon>
                    <span>{{'btn_bulk_upload' | translate}}</span>
                </xa-button>
            </div>
        </div>
    </div>
    <div class="wrapper"
         *ngIf="(!uploadModeSelector.showSPI && !uploadModeSelector.showMultipleUpload) || uploadModeSelector.isDisabled">
        <div class="no-document-div">
            <img src="assets/icons/no-document.svg"
                 alt="no document">
            <label class="no-vehicle-content">
                {{ (uploadModeSelector.isDisabled ? 'start_job' : 'bulk_spi_disabled') | translate}}
            </label>

        </div>
    </div>
    <div class="footer-full-wrapper">
        <div class="footer-image-wrapper">
            <div class="image-wrapper">
                <img alt=""
                     src="assets/img/{{ uploadModeSelector.footerLogo  || 'addenda.png'}}" />
            </div>
        </div>
        <div class="footer-button-wrapper">
            <ng-content></ng-content>
        </div>
    </div>
</div>