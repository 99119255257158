export const CASE_STATUS = {
  'Draft': 'Draft',
  'PendingInformation': 'PendingInformation',
  'CustomerSubmitted': 'CustomerSubmitted',
  'InReview': 'InReview',
  'Completed': 'Completed'
}

export const CASE_STATUS_TEXT_DIC = {
  'Draft': 'Draft',
  'PendingInformation': 'Pending Information',
  'CustomerSubmitted': 'Customer Submitted',
  'InReview': 'In Review',
  'Completed': 'Completed'
}

export const DAMAGE_STATUS = {
  'NoDamageDetected': 'Damage not detected',
  'DamageDetected': 'Damage detected',
  'FailedAIAnalysis': 'Failed',
  'NotAvailable': 'Not available'
}

export const DAMAGE_STATUS_TEXT_DIC = {
  'NoDamageDetected': 'No Damage Detected',
  'DamageDetected': 'Damage Detected',
  'FailedAIAnalysis': 'Failed AI Analysis',
  'NotAvailable': 'Not Available'
}

export const DISCREPANCY_FLAG_TEXT_DIC = {
  'DiscrepancyFound': {
    'display': 'Discrepancy Observed', 
    'value': 'DiscrepancyFound', 
  },
  'NoDiscrepancyFound': {
    'display': 'No Discrepancy Observed', 
    'value': 'NoDiscrepancyFound', 
  }
}

export const QUOTE_ASSESSMENT_SETTINGS_TYPE = {
  'MILEAGE': 'MileageSettings',
}

export const IN_PROGRESS_CASES = [ CASE_STATUS.PendingInformation, CASE_STATUS.CustomerSubmitted, CASE_STATUS.InReview ]