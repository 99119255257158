<div class="close"
     *ngIf="image || showCamera">
     <progress-check *ngIf="this.isLandscapeModeActive"
                     [tracks]="videoSteps"
                     #progress></progress-check>
     <xa-timer [showDay]="false"
               [showSeconds]="true"
               *ngIf="isRecording"
               [showMinified]="true"
               class="assignee-timer"
               [seconds]="0"></xa-timer>
     <mat-icon (click)="closeCamera();"
               [svgIcon]="'cross'"></mat-icon>
</div>
<div class="camera-wrapper"
     [ngClass]="{'show-cam':(showCamera || image) && this.isLandscapeModeActive, 'and': platform.ANDROID, 'ios': platform.IOS}">
     <div class="camera-container"
          [hidden]="!showCamera">
          <video #videoElement
                 autoplay
                 [ngClass]="{'ios' : platform.IOS}"
                 playsinline>
          </video>
     </div>
     <div class="shutter-container"
          *ngIf="showCamera && !processing">
          <mat-icon (click)="isRecording?stopRecording(): startRecording()"
                    [svgIcon]="isRecording? 'stop': 'shutter'"></mat-icon>
          <span>{{(isRecording ? 'recording': 'record') | translate}}</span>
     </div>
</div>
<div class="bottom-bar"
     *ngIf="(image || showCamera) && currentText">
     <span [innerHTML]="currentText">
     </span>
     <xa-button size="sm"
                *ngIf="isRecording"
                (click)="emitDone()">{{ (activeIndex === (videoSteps?.length -1)? 'done':'next') | translate}}</xa-button>
</div>
<div class="orientation-message"
     *ngIf="!this.isLandscapeModeActive && showCamera">
     <img src="assets/img/video_flip.png" />
     <span>{{'switch_to_landscape' | translate}}</span>
</div>