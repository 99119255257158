<div class="dialog-container">
    <div mat-dialog-close
         class="btn-close"
         (click)="close()">
        <mat-icon class="cross"
                  svgIcon="cross"
                  aria-hidden="false"
                  aria-label='close icon'></mat-icon>
    </div>

    <div mat-dialog-content
         class="img-wrapper">
        <video controls>
            <source [src]="data.videoUrl"
                    type="video/mp4">
            Your browser does not support HTML video.
        </video>
        <xa-button class="delete"
                   type="basic"
                   *ngIf="data.delete"
                   (click)="deleteVideo();">
            <mat-icon svgIcon="trash"></mat-icon>
            {{'delete' | translate}}</xa-button>
    </div>
</div>