<div class="dialog-container">
    <div class="header">
        <h3>{{ 'reserve_detail' | translate }}</h3>
        <div class="close-icon"
             (click)="cancel();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div mat-dialog-content
         class="adjustment-body">
        <div class="form-container">
            <div class="table-container"
                 *ngIf="tableData && tableData.length > 0">
                <div class="table-wrapper reserve-info-table">
                    <xa-table [config]="reserveTableConfig"
                              [tableData]="tableData"
                              [i18n]="i18n">
                    </xa-table>
                </div>
            </div>
        </div>
    </div>
</div>