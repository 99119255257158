import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EmailRequest } from 'src/app/dialogs/request-approval-dialog/request-approval-dialog.component';
import { environment } from 'src/environments/environment';
import { FileService } from '../file/file.service';
import { ChatConversations } from 'src/app/widgets/chat/models/message';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  openWhatsapp$ = new Subject<string>();
  /**
   * constructor
   * @param http
   */
  constructor(
    private http: HttpClient,
    private fileService: FileService
  ) {
  }

  /**
   * send approval message
   */
  sendApprovalMessage(data: any, repairGuid: string): Observable<any> {
    const localTime = new Date().toString();
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/message/${repairGuid}/estimate-approval`;
    return this.http.post(url, data, { headers });
  }

  /**
   * send Invoice message
   */
  sendInvoiceMessage(data: any, repairGuid: string): Observable<any> {
    const localTime = new Date().toString();
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/message/${repairGuid}/invoice`;
    return this.http.post(url, data, { headers });
  }

  /**
   * send Quote approval message
   */
  sendQuoteApprovalMessage(data: any, caseGuid: string): Observable<any> {
    const localTime = new Date().toString();
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/message/estimate-approval/case/${caseGuid}`;
    return this.http.post(url, data, { headers });
  }

  /**
   * get message template
   */
  getMessageTemplate(repairId: string, domainId: number, id: number): Observable<any> {
    const url = `${environment.xaAggregationApi}/repaircommunicationtemplate/${repairId}/${domainId}/${id}`;
    return this.http.get(url);
  }

  /**
   * get message template
   */
  getRemainderMessageTemplate(claimGuid: string, domainId:number, collectionId: number, objectId: number, templateKey:string, recoveryGuid:string): Observable<any> {
    const headers = new HttpHeaders().append('objectGuid', claimGuid).append('recoveryGuid', recoveryGuid);
    const url = `${environment.xaAggregationApi}/claim-recovery-media-communicationtemplate-detail/${claimGuid}/${domainId}/${templateKey}/${collectionId}/${objectId}`;
    return this.http.get(url, { headers });
  }

  /**
   * send remainder message
   */
  sendRemainderMessage(body:any, objectGuid:string, recoveryGuid:string):Observable<any>{
    const url = `${environment.fnolApiUrl}/api/Message/recovery-remainder/${objectGuid}/${recoveryGuid}`;
    return this.http.post(url, body);
  }

  /**
   * set message
   */
  setMessage(body, repairId): Observable<any> {
    const localTime = new Date().toString();
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/message/${repairId}/send-message`;
    return this.http.post(url, body, { headers });
  }

  /**
   * send customer message
   */
  sendFreeCommunicationMessage(emailBody: EmailRequest, reportProgress?: boolean, observe?: 'events'): Observable<any>{
    const url = `${environment.communicationApi}/api/Message/email`;
    const formData = new FormData();
    formData.append('ToEmail', emailBody.email?.join(','));
    if (emailBody.cc) {
      formData.append('CCEmail', emailBody.cc);
    }
    formData.append('Content', emailBody.message);
    formData.append('Subject', emailBody.subject);
    formData.append('ObjectId', emailBody.objectId);
    if (emailBody.files && emailBody.files.length > 0) {
      emailBody.files.forEach((file) => {
        formData.append('files', file);
      });
    }
    return this.fileService.uploadFile(url, formData, reportProgress, observe);
  }

  /**
   * get whatsapp message
   */
  getWhatsAppMessages(objectId: number, domainId: number): Observable<any> {
    const url = `${environment.communicationApi}/api/BidirectionalConversation/${objectId}/${domainId}`;
    return this.http.get(url);
  }

  /**
   * post whatsapp message
   */
  postWhatsAppMessage(chatConversation: ChatConversations): Observable<any> {
    const url = `${environment.communicationApi}/api/BidirectionalConversation`;
    return this.http.post(url, chatConversation);
  }

  /**
   * post whatsapp message
   */
  updateWhatsAppMessage(phoneNumber: string, domainId: number, objectId: number): Observable<any> {
    let url = `${environment.communicationApi}/api/BidirectionalConversation/updatemessagestatus/${phoneNumber}/${domainId}`;
    url = objectId
      ? url + `/${objectId}`
      : url + '/0';

    return this.http.put(url, null);
  }

  /**
   * post whatsapp message
   */
  postWhatsAppMessageWithAttachment(chatConversation: ChatConversations, chatId?: string, reportProgress?: boolean, observe?: 'events'): Observable<any> {
    const url = `${environment.communicationApi}/api/BidirectionalConversation`;
    const formData = new FormData();
    formData.append('message', chatConversation.message);
    formData.append('recipient', `${chatConversation.recipient}`);
    if(chatId){
      formData.append('contactId', `${chatId}`);
    }
    formData.append('replyToMessageId', '');
    formData.append('isInteractiveToBeInitiated', `${chatConversation.isInteractiveToBeInitiated}`);
    formData.append('channel', '1');
    formData.append('isGeneralChat', `${chatConversation.isGeneralChat}`);
    formData.append('haveAttachment', chatConversation.haveAttachment.toString());

    if (chatConversation.objectId) {
      formData.append('objectId', `${chatConversation.objectId}`);
    }

    if (chatConversation.file) {
      formData.append('file', chatConversation.file);
    }
    formData.append('domainId', `${chatConversation.domainId}`);
    return this.fileService.uploadFile(url, formData, reportProgress, observe);
  }

  /**
    * * @returns
   */
  getGeneralCommunicationMessages(queryParams: string): Observable<any> {
    const url = `${environment.communicationApi}/odata/BidirectionalConversation${queryParams}`;
    return this.http.get(url);
  }

  /**
    * * @returns
   */
  getGeneralCommunicationMessagesCount(): Observable<any> {
    const url = `${environment.communicationApi}/odata/BidirectionalConversation?$apply=aggregate(UnreadConversationCount with sum as UnreadConversationCount)`;
    return this.http.get(url);
  }
}
