import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { allowExtension, photosSizeLimit } from 'src/app/config/constants/app.constants';

@Component({
  selector: 'chq-vertical-image-preview',
  templateUrl: './chq-vertical-image-preview.component.html',
  styleUrls: [ './chq-vertical-image-preview.component.scss' ]
})
export class ChqVerticalImagePreviewComponent implements OnInit {
  @Input() steps?: UploadStepModel[];
  @Input() isMultipleUpload: boolean;
  @Input() maximumImages:number;
  @Input() page: string;
  @Input() aiAnalysis: boolean;
  @Output() selection: EventEmitter<UploadStepModel> = new EventEmitter();
  @Output() refresh: EventEmitter<void> = new EventEmitter();
  currentSteps?: UploadStepModel[];
  public imageLoading = {};

  /**
   * constructor
   */
  constructor(private monitorService: MonitorService) { }


  /**
   * on step selected
   * @param step 
   */
  selectStep(step: UploadStepModel): void {
    this.monitorService.logEvent('selectStep', [ 'ChqVerticalImagePreviewComponent', 'addenda-quote', {
      step
    } ]);
    this.selection.emit(step);
  }

  /**
   * returns steps
   */
  get previewSteps(): UploadStepModel[] {
    return this.currentSteps;
  }

  /**
   * trigger refresh
   */
  refreshData(): void {
    this.refresh.emit();
  }

  /**
   * onInit
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChqVerticalImagePreviewComponent', 'addenda-quote' ]);
    for (let i = 0; i < this.steps?.length; i++) {
      this.imageLoading[this.steps[i]['stepName']] = false;
    }
    const currentPhotos = this.steps?.filter((currentStep: UploadStepModel) => {
      return currentStep.isReviewOnly !== true &&
        (currentStep.isAdditionalDocumentFromReviewPageOnly != true || currentStep.image || currentStep.url);
    });
    if ((currentPhotos?.length != this.steps?.length) || (this.isMultipleUpload && currentPhotos?.length < ( this.maximumImages || 40))) {
      this.currentSteps = currentPhotos.concat([ {
        stepName: this.page === 'photos' ? 'additional_image' : 'additional_documents',
        uploadStatus: 'notUploaded',
        url: '',
        image: '',
        index: -1,
        isAdditionalDocumentFromReviewPageOnly: true,
        sizeLimit: this.steps[0]?.sizeLimit || photosSizeLimit,
        allowExtension: this.steps[0]?.allowExtension || allowExtension,
        mandatory: false,
        isUploadSuccess: false,
        uploadInProgress: false,
        isUploadFailed: false,
        isSkipped: false,
        templateGuid: this.steps[0]?.templateGuid || '',
        partTemplateGuid: this.steps[0].partTemplateGuid || '',
        placeHolderUrl: '/assets/icons/additional-image.svg'
      } ]);
      return;
    }
    this.currentSteps = this.steps;
  }
}
