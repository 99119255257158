import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'progress-check',
  templateUrl: './progress-check.component.html',
  styleUrls: [ './progress-check.component.scss' ]
})
export class ProgressCheckComponent implements OnInit {
  @Input() tracks;
  public track:any[];

  /**
   * ngOnInit
   * 
   */
  ngOnInit():void{
    this.track = cloneDeep(this.tracks);
  }
}
