import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import { Components, exportMapper, FooterActions, HeaderActions, IconActions, PhotosAction, XADocuments, XAFileReviewSection, XaHeaderAction, XaImageAction } from '../xa-photo-upload-mode-selecter/xa-file-upload.model';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { catchError, forkJoin, Observer, of } from 'rxjs';
import * as dayjs from 'dayjs';
import { documentPartMapper } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import { CommonService } from 'src/app/services/common/common.service';
import { FileService } from 'src/app/services/file/file.service';
import { MediaCollectionService } from 'src/app/services/media-collection/media-collection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { XaFileReviewSectionComponent } from '../xa-file-review-section/xa-file-review-section.component';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';


@Component({
  selector: 'xa-documents',
  templateUrl: './xa-documents.component.html',
  styleUrls: [ './xa-documents.component.scss' ]
})
export class XaDocumentComponent implements OnInit{
  @Input() xaDocument:XADocuments;

  //components
  public currentStep:UploadStepModel;
  public hideUploader:boolean;
  public noDocumentData:boolean;
  public files:UploadStepModel[];
  public hideReviewSection:boolean;
  public hideSelector:boolean;
  public displayConsolidatedAnalysis:boolean;

  //View child
  @ViewChild('reviewSection') reviewSection:XaFileReviewSectionComponent;
  @Output() documentUpload: EventEmitter<any> = new EventEmitter();
  @Input() isCustomerLink:boolean;
  public consolidatedFileReviewSection:XAFileReviewSection;

  //private components
  public mediaTemplate: any;
  private consolidatedStep:UploadStepModel
  public dataLoading:boolean;
  private mediaPartTemplates: any[];
  private consolidatedDocumentId:number;

  public consolidatedDocumentStep:UploadStepModel;

  /**
  * constructor
  */
  constructor( private monitorService: MonitorService,
      private commonService:CommonService,
      private mediaCollectionService:MediaCollectionService,
      private fileService: FileService,
      private readonly quoteManagementService: QuoteManagementService) {}

  /**
   * on init
   */
  ngOnInit(): void {
    this.loadMediaTemplateDetails();
  }

  /**
     *handle mode selection
     * @param $event 
  */
  handleMode($event: any): void {
    if ($event.mode === 'single') {
      this.xaDocument.displaySelector = false;
      this.xaDocument.displayReviewSection =true;
      this.xaDocument.isConsolidated = false;
      this.hideReviewSection = false;
      this.xaDocument.fileReviewSection = { ...this.xaDocument.fileReviewSection, files: this.files.filter(x=>x.stepName?.toLowerCase() !== 'consolidated document') };
    } else {
      this.xaDocument.displayReviewSection =true;
      this.hideReviewSection = true;
      this.xaDocument.isConsolidated = true;
      if(!this.xaDocument.fileReviewSection.headerActions){
        this.xaDocument.fileReviewSection.headerActions = [];
      }
      this.xaDocument.fileReviewSection.headerActions = [ {
        label: 'consolidated_document_analysis',
        icon: 'chip',
        type: HeaderActions.custom,
        requireFileUpload: true,
        location: 'review',
        btnType: 'default',
        btnColor: 'primary',
        callback: this.handleDocumentAnalysis.bind(this)
      } ];
      this.xaDocument.fileReviewSection = { ...this.xaDocument.fileReviewSection, files: this.files.filter(x=>x.stepName?.toLowerCase() === 'consolidated document') };
      setTimeout(() => {
        this.triggerUpload(this.xaDocument.fileReviewSection.files[0]);
      })
    }
  }

  /**
   * handle document analysis
   */
  handleDocumentAnalysis(): void {
    this.consolidatedFileReviewSection = {
      domainId: this.xaDocument.domainId,
      inspectionId: 0,
      displayDialog: true,
      files: [],
      type: 'photo',
      multipleUpload: false,
      permissionTag: '',
      iconContainerClass: '',
      maxFileLength: 20,
      minFileLength: 20,
      enableBulk: false,
      enableSPI: true,
      isEditable: true,
      bulkUploadInspectionItemId: 0,
      objectId: this.xaDocument?.objectId,
      templateId: 0,
      aiReviewSection: false,
      footerActions: [],
      headerActions: [
      ],
      actions: [
        {
          icon: 'download-arrow',
          iconClass: 'border-light-gray',
          iconAction: IconActions.download,
          displayIndicator: false
        }
      ],
      objectType: '',
      label: ''
    }
    this.commonService.showLoading();
    this.mediaCollectionService.ocrDetails(this.xaDocument.objectId, this.xaDocument.domainId, this.consolidatedDocumentId).subscribe({
      next: (res:any)=>{
        this.commonService.hideLoading();
        const attachments = [];
        const fileNames =[];
        if(res.data ){
          if(res?.data?.file_url||res?.data?.entities?.file_url){
            attachments.push(res?.data?.file_url||res?.data?.entities?.file_url);
            fileNames.push('police_report');
          }
          if(res?.data?.dlDetails?.file_url){
            attachments.push(res?.data?.dlDetails?.file_url);
            fileNames.push('driving_license');
          }
          if(res?.data?.rcDetails?.file_url){
            attachments.push(res?.data?.rcDetails?.file_url);
            fileNames.push('registration_card');
          }
        }
        if (attachments?.length > 0) {
          const currentObj = [];
          attachments.forEach((element: string, index:number) => {
            if (element) {
              let extension = element.split('.').pop()?.toLowerCase();
              if(extension.includes('?')){
                extension = extension.split('?')[0];
              }
              const obj:UploadStepModel = {
                'url': element,
                'stepName': fileNames[index],
                'mandatory': false,
                'allowExtension': [],
                'sizeLimit': 0,
                'index': 0,
                'contentType': `application/${extension}`,
                'placeHolderUrl': '',
                'uploadStatus': 'success',
                'templateGuid': '',
                'partTemplateGuid': '',
                'uploadInProgress': false,
                'isSkipped': true,
                'isSkipEnabled': true,
                'isUploadFailed': false,
                'isUploadSuccess': true
              }
              obj.action = exportMapper(obj, this.consolidatedFileReviewSection.actions);
              currentObj.push(obj);
            }
          });
          this.displayConsolidatedAnalysis = true;
          this.hideReviewSection = true;
          this.consolidatedFileReviewSection = { ...this.consolidatedFileReviewSection, files: currentObj };
        }
      },
      error: ()=>{
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * back to review
   */
  backToReview():void{
    this.displayConsolidatedAnalysis = false;
    this.hideReviewSection = false;
  }

  /**
     * handle icon actions
     */
  handleIconActions(action:XaHeaderAction):void{
    switch(action.type){
    default:
      if(action.callback){
        action.callback(action, '');
      }
    }
  }

  /**
   * trigger upload
   */
  triggerUpload(step:UploadStepModel):void{
    this.reviewSection.triggerFileUpload(step);
  }

  /**
   * handle input actions
   */
  handleAction($event:PhotosAction):void{
    const { step, action } = $event;
    switch(action.iconAction){
    case IconActions.delete:
      this.showDeleteConfirmation(step);
      break;
    case IconActions.upload:
    case IconActions.edit:
      this.reviewSection.triggerFileUpload(step);
      break;
    case IconActions.download:
      this.reviewSection.download(step);
    }
    
  }

  /**
   * handleUpload
   */
  handleUpload($event):void{
    this.hideUploader = true;
    setTimeout(()=>{
      this.reviewSection.handleImageOutput($event.fileUploadEvent);
    })
  }

  /**
   * footer actions
   */
  handleFooterActions(action:XaImageAction):void{
    switch(action.type){
    case FooterActions.custom:
      action.callback(action);
      break
    }
  }


  /**
   * map media template Data
   * @param mediaData
   */
  mapMediaTemplate(mediaData: any):void {
    if(this.mediaTemplate) {
      try {
        for(const element of this.mediaTemplate.mediaTemplates) {
          const media = element;
          const mediaDataTemplate = mediaData.find((x:any)=> x.mediaTemplateGuid == media.mediaTemplateGuid);
          if(mediaDataTemplate) {
            for(const element of media.mediaPartTemplates) {
              const mediaPart = element;
              const mediaDataPart = mediaDataTemplate.mediaParts.find((x: any)=> x.mediaPartTemplateGuid == mediaPart.mediaPartTemplateGuid);
              mediaPart.fileName = mediaDataPart.fileName;
              mediaPart.contentType = mediaDataPart.contentType;
              mediaPart.mediaGuid = mediaDataTemplate.mediaGuid;
              mediaPart.mediaPartGuid = mediaDataPart.mediaPartGuid;
              mediaPart.createdDate = mediaDataPart.createdDate;
              mediaPart.updatedDate = mediaDataPart.updatedDate;
              mediaPart.image = mediaDataPart.getURL;
              mediaPart.isUploadSuccess = false;
              mediaPart.isLocked = mediaDataPart.isLocked;
              if (mediaPart.mediaPartGuid && mediaPart.mediaPartGuid != '00000000-0000-0000-0000-000000000000') {
                mediaPart.isUploadSuccess = true;
                if (mediaDataPart.ocrTagDetail) {
                  this.documentUpload.emit({
                    'tags': mediaDataPart.ocrTagDetail?.ocrTags?.map((tg) => {
                      return {
                        key: tg.tagName,
                        value: tg.tagValue
                      }
                    }), 'step': mediaPart.name
                  });
                }
              }
            }
          }
        }
        this.mediaTemplate = { ...this.mediaTemplate };

      } catch (error) {
        console.log(error);
      }
    }
  }

  /**
   * media template by id
   */
  getMediaTemplateByObject():void {
    this.mediaPartTemplates = this.commonService.getAllChild(this.mediaTemplate?.mediaTemplates, 'mediaPartTemplates', 'mediaTemplateGuid',
      { 'parentProp': 'name', 'childProp': 'parentName' });
    const files = [];
    for (let i = 0; i < this.mediaPartTemplates.length; i++){
      const mediaPart = this.mediaPartTemplates[i];
      const documentStep = documentPartMapper(mediaPart, i);
      const isAdditionalImage = documentStep.stepName.toLowerCase().indexOf('additional document') != -1;
      const isAdditionalDocumentFromReviewPageOnly = isAdditionalImage && !documentStep.url;
      const docStep = {
        ...documentStep, mandatory: mediaPart.mandatory, index: i,
        isAdditionalDocumentFromReviewPageOnly,
        contentType: mediaPart.contentType,
        fileName: mediaPart.fileName,
        parentName: mediaPart.parentName,
        isFront: mediaPart.frontSide,
        updatedDate: mediaPart.mediaPartGuid != '00000000-0000-0000-0000-000000000000' ? mediaPart.updatedDate: '',
        uploadedDate: mediaPart.mediaPartGuid != '00000000-0000-0000-0000-000000000000' ? mediaPart.createdDate: '',
        domainId: this.xaDocument.domainId, objectId: this.xaDocument.objectId,
        sizeLimit: mediaPart.sizeLimit, allowExtension: mediaPart.allowExtension,
        uploadedPartGuid: mediaPart.mediaPartGuid != '00000000-0000-0000-0000-000000000000' ? mediaPart.mediaPartGuid : '',
        uploadedGuid: mediaPart.mediaGuid != '00000000-0000-0000-0000-000000000000' ? mediaPart.mediaGuid:'',
        partTemplateGuid: mediaPart.mediaPartTemplateGuid, templateGuid: mediaPart['mediaTemplateGuid'],
        isLocked: mediaPart.isLocked,
        action: []
      }
      docStep.action = exportMapper(docStep, this.xaDocument.fileReviewSection.actions );
      files.push(docStep);
      this.checkRouteParams();
    }
    const filesUploaded = files.filter(x=>x.stepName?.toLowerCase() !== 'consolidated document' && x.url);
    if(filesUploaded?.length === 0){
      this.consolidatedDocumentStep = files.find(x=>x.stepName?.toLowerCase() === 'consolidated document');
    }
    this.files = files;
    this.consolidatedStep = files.find(x=>x.stepName?.toLowerCase() === 'consolidated document');
  }

  /**
   * check route params
   */
  checkRouteParams():void {
    if(this.xaDocument.fromPersonal){
      this.xaDocument.fileReviewSection.files = this.xaDocument.fileReviewSection.files.filter((step:UploadStepModel)=>{
        return step.stepName.indexOf('Vehicle Registration') != -1;
      });
    }
  }

  /**
   * set Observable
   */
  setObservable(file, obj):any{
    if(this.hideReviewSection){
      this.commonService.showLoading();
    }
    if(obj.isOcrEnabled){
      return this.mediaCollectionService.uploadDocumentOCR(file, obj);
    }else
    if(obj.mediaPartGuid){
      return this.mediaCollectionService.updateDocument(file, obj);
    }else{
      return this.mediaCollectionService.uploadDocument(file, obj);
    }
  }

  /**
   * map media template Data
   * @param mediaData
   */
  mapMediaTemplateReload(partTemplateGuid: string, resp: any): void {
    try {
      const mediaPartTemplate = this.mediaPartTemplates?.find((x: any) => x.mediaPartTemplateGuid == partTemplateGuid);
      if (mediaPartTemplate) {
        mediaPartTemplate.mediaGuid = resp.mediaGuid;
        mediaPartTemplate.mediaPartGuid = resp.mediaPartGuid;
        if(this.xaDocument.onDocumentUploadAction){
          this.xaDocument.onDocumentUploadAction();
        }
      }
    } catch (error) {
      console.log(error);
    }

  }
  
  /**
   * set observer
   */
  setObserver(isNew:boolean, step:UploadStepModel):Observer<any>{
    if (!step.uploadedGuid ||!step.uploadedPartGuid) {
      return {
        next: (res: any):void => {
          this.xaDocument.displaySelector = false;
          this.hideReviewSection =false;

          if (step.isOcrEnabled) {
            this.documentUpload.emit({ 'tags': res.ocrTags, 'step': step.stepName });
            step.uploadInProgress = false;
            step.isSkipped = false;
            step.isUploadSuccess = true;
            step.isUploadFailed = false;
            step.uploadStatus = 'success';
            step.uploadedGuid = res?.mediaGuid;
            step.uploadedPartGuid = res?.mediaPartGuid;
            this.mapMediaTemplateReload(step.partTemplateGuid, res);
            return;
          }
          const responseData = res?.data;
          if(step.stepName?.toLowerCase() === 'consolidated document') {
            this.consolidatedDocumentStep.url = responseData?.blobUrl
          }else{
            const { files } = this.xaDocument.fileReviewSection;
            files.forEach((el)=> {
              if(el.partTemplateGuid === step.partTemplateGuid) {
                el.url = responseData?.blobUrl
              }
            })
          }
          
          const mediaPartTemplate = this.mediaPartTemplates?.find((x: any) => x.mediaPartTemplateGuid == responseData?.mediaPartTemplateId);
          if(mediaPartTemplate) {
            mediaPartTemplate.mediaGuid = responseData.mediaGuid;
            mediaPartTemplate.mediaPartGuid = responseData.mediaPartGuid;
            if(this.xaDocument.onDocumentUploadAction){
              this.xaDocument.onDocumentUploadAction();
            }
            this.updateUploadStatus('success', step, null, responseData);
          }
          //Update discrepancy status for quote assessment
          if(this.xaDocument?.options?.isQuoteAssessmentModule) {
            this.quoteManagementService.updateDiscrepancyFlag$.next(true);
          }
        },
        error: (err: any):void => {
          if(err.status != 401) {
            try{
              this.updateUploadStatus( 'failed', step, err);
            }catch(e){
              this.updateUploadStatus('failed', step);
            }
          }
          this.monitorService.logException(err, SeverityLevel.Error);
        },
        complete: ():void => this.commonService.hideLoading(),
      }
    }
    
    return {
      next: (res: any): void => {
        this.hideReviewSection = false;
        this.xaDocument.displaySelector = false;
        if (step.isOcrEnabled) {
          this.documentUpload.emit({ 'tags': res.ocrTags, 'step': step.stepName });
          step.uploadInProgress = false;
          step.isSkipped = false;
          step.isUploadSuccess = true;
          step.isUploadFailed = false;
          step.uploadStatus = 'success';
          step.uploadedGuid = res?.mediaGuid;
          step.uploadedPartGuid = res?.mediaPartGuid;
          this.mapMediaTemplateReload(step.partTemplateGuid, res);
          return;
        }
        this.updateUploadStatus('success', step, null, res );
        if(this.xaDocument.onDocumentUploadAction){
          this.xaDocument.onDocumentUploadAction();
        }
        //Update discrepancy status for quote assessment
        if(this.xaDocument?.options?.isQuoteAssessmentModule) {
          this.quoteManagementService.updateDiscrepancyFlag$.next(true);
        }
      },
      error: (err: any):void => {
        if(err.status != 401) {
          try{
            this.updateUploadStatus( 'failed', step, err);
          }catch(e){
            this.updateUploadStatus('failed', step);
          }
        }
        this.monitorService.logException(err, SeverityLevel.Error);
      },
      complete: ():void =>this.commonService.hideLoading(),
    };
  }

  /**
   * update document status
   */
  updateUploadStatus(status: string, currentStep: UploadStepModel, err?: any, responseData?: any, isBulk?: boolean): void {
    this.monitorService.logEvent('updateUploadStatus', [ 'ChqImagesUploaderComponent', 'addenda-quote', {
      status,
      currentStep,
      responseData,
      isBulk
    } ]);

    if (status == 'failed') {
      currentStep.uploadInProgress = false;
      currentStep.isSkipped = false;
      currentStep.isUploadSuccess = false;
      currentStep.isUploadFailed = false;
      if (isBulk) {
        currentStep.isUploadFailed = true;
      } else {
        currentStep.imageFile = null;
        currentStep.image = null;
      }

      
      currentStep.action = exportMapper(currentStep, this.xaDocument.fileReviewSection.actions);
      const message = (err && err.error?.msg) ? err.error?.msg
        : `Your photo ‘${currentStep.stepName}’ failed to upload. Please try to upload again.`
      const status = err ? err.status : 0;
      this.commonService.showToast(status, message);
      if (this.currentStep.isAdditionalDocumentFromReviewPageOnly) {
        const step: UploadStepModel = {
          stepName: 'Additional Documents',
          uploadStatus: 'notUploaded',
          url: '',
          image: '',
          index: -1,
          isAdditionalDocumentFromReviewPageOnly: true,
          sizeLimit: this.currentStep.sizeLimit,
          allowExtension: this.currentStep.allowExtension,
          mandatory: false,
          isUploadSuccess: false,
          uploadInProgress: false,
          isUploadFailed: false,
          isSkipped: false,
          isMassUpload: currentStep.isMassUpload,
          templateGuid: this.currentStep.templateGuid,
          partTemplateGuid: this.currentStep.partTemplateGuid,
          placeHolderUrl: '/assets/icons/additional-image.svg'
        }
        this.currentStep = step;
      }
    } else if (status == 'success') {
      currentStep.uploadInProgress = false;
      currentStep.isSkipped = false;
      currentStep.isUploadFailed = false;
      currentStep.isUploadSuccess = true;
      currentStep.uploadStatus = 'success';
      currentStep.updatedDate = dayjs().toISOString();
      currentStep.uploadedDate = dayjs().toISOString();
      currentStep.isAdditionalDocumentFromReviewPageOnly = false;

      if (responseData) {
        if (currentStep.originalName) {
          currentStep.stepName = currentStep.originalName;
        }

        currentStep.uploadedGuid = responseData.mediaGuid;
        currentStep.uploadedPartGuid = responseData.mediaPartGuid;
      }

    }
    
    currentStep.action = exportMapper(currentStep, this.xaDocument.fileReviewSection.actions);

    this.xaDocument.fileReviewSection = { ...this.xaDocument.fileReviewSection };
    const uploaded = this.xaDocument.fileReviewSection.files.filter((step: UploadStepModel) => {
      return step.uploadInProgress === true;
    });
    const uploadedDocs = this.xaDocument.fileReviewSection.files.filter((step: UploadStepModel) => {
      return step.url;
    })
    if(uploadedDocs?.length > 0){
      this.consolidatedDocumentStep = null;
    }
    this.commonService.inProgressSteps.next(uploaded.length);
  }

  /**
   * load media Template Details
   */
  loadMediaTemplateDetails():void{
    this.dataLoading = true;
    const observableObj:any = {
      //mediaTemplate: this.mediaCollectionService.getMediaTemplate(this.xaDocument.domainId)
      mediaTemplate: this.mediaCollectionService.getMediaTemplate()
    }
    const result = forkJoin(observableObj).pipe(
      catchError(error => of(error))
    );
    result.subscribe((dict: any) => {
      if (dict) {
        const mediaTemplate = dict.mediaTemplate;
        if (mediaTemplate) {
          const mediaTemplateSelected = mediaTemplate.data;
          if (mediaTemplateSelected.length > 0) {
            const { id, collectionGuid, countryID } = mediaTemplateSelected[0];
            this.documentUpload.emit({ 'value': countryID, 'step': 'country' });
            this.documentUpload.emit({ 'value': id, 'step': 'documentCollectionId' });
            this.xaDocument.fileReviewSection = { ...this.xaDocument.fileReviewSection, collectionGuid: collectionGuid };
            const detailResult = forkJoin({
              mediaTemplateDetail: this.mediaCollectionService.getMediaTemplateById(collectionGuid),
              mediaDetail: this.mediaCollectionService.getMediaDocumentData(id, this.xaDocument.objectId, this.xaDocument.domainId)
            }).pipe(
              catchError(error => of(error))
            )
            detailResult.subscribe((detailDict: any) => {
              if (detailDict) {
                const { mediaTemplateDetail, mediaDetail } = detailDict;
                this.mediaTemplate = mediaTemplateDetail.data;
                this.mediaTemplate.mediaTemplates = mediaTemplateDetail.data.mediaTemplates.sort((media: any, mediab: any) => {
                  return media.orderNumber - mediab.orderNumber;
                })
                if (mediaDetail.data) {
                  this.mapMediaTemplate(mediaDetail.data.medias);
                }
                this.getMediaTemplateByObject();
               
                const steps = this.xaDocument.fileReviewSection.files;
                const additionalDocument = steps.find(x => x.stepName.includes('Additional Document') && !x.uploadedPartGuid);
                if (additionalDocument) {
                  additionalDocument.originalName = additionalDocument.stepName;
                  additionalDocument.stepName = additionalDocument.parentName;
                  additionalDocument.placeHolderUrl = '/assets/icons/additional-image.svg';
                }
                this.dataLoading = false;
                if(this.xaDocument.onDocumentDataLoad){
                  this.xaDocument.onDocumentDataLoad(detailDict, this.files);
                }
                const hasFiles = this.files.some(x => x.url);
                if(hasFiles){
                  const consolidatedFile = this.files.find(x => x.stepName === 'Consolidated Document' && x.url);
                  if(consolidatedFile){
                    if(!this.xaDocument.fileReviewSection.headerActions){
                      this.xaDocument.fileReviewSection.headerActions = [];
                    }
                    this.xaDocument.fileReviewSection.headerActions = [ {
                      label: 'consolidated_document_analysis',
                      icon: 'chip',
                      type: HeaderActions.custom,
                      requireFileUpload: true,
                      location: 'review',
                      btnType: 'default',
                      btnColor: 'primary',
                      callback: this.handleDocumentAnalysis.bind(this)
                    } ];
                    this.xaDocument.fileReviewSection = { ...this.xaDocument.fileReviewSection, files: this.files.filter(x=>x.stepName?.toLowerCase() === 'consolidated document')
                      , observer: this.setObserver.bind(this) };
                  } else{
                    this.xaDocument.fileReviewSection = { ...this.xaDocument.fileReviewSection, files: this.files.filter(x=>x.stepName?.toLowerCase() !== 'consolidated document')
                      , observer: this.setObserver.bind(this) };
                  }
                  this.xaDocument.displaySelector = false;
                  this.xaDocument.displayReviewSection =true;
                }
                const consolidatedDocument = mediaTemplateDetail.data?.mediaTemplates?.find(x => x.name?.toLowerCase() === 'consolidated document');
                if(consolidatedDocument){
                  this.consolidatedDocumentId = consolidatedDocument.id;
                }
                this.xaDocument.fileReviewSection = { ...this.xaDocument.fileReviewSection, observer: this.setObserver.bind(this), observable: this.setObservable.bind(this) };
              }
              this.commonService.hideLoading();
            }, (err) => {
              this.commonService.hideLoading();
              this.monitorService.logException(err, SeverityLevel.Error);
            });

          } else {
            this.commonService.hideLoading();
          }
        }else {
          this.noDocumentData = true;
          this.commonService.hideLoading();
        }

      } else {
        this.noDocumentData = true;
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * show delete confirmation
   */
  showDeleteConfirmation(uploadStepModel: UploadStepModel): void {
    this.commonService.openDeleteDialog('Document', '')
      .afterClosed().subscribe((data) => {
        if (data) {
          this.deleteDocument(uploadStepModel);
        }
      });
  }
  
  /**
   *  delete image
   */
  deleteDocument(uploadStepModel: UploadStepModel): void {
    this.commonService.showLoading();
    const { uploadedGuid, uploadedPartGuid, objectId, domainId } = uploadStepModel;

    this.mediaCollectionService.deleteMediaTemplate(uploadedGuid, uploadedPartGuid, objectId, domainId).subscribe((resp: any) => {
      const index = this.xaDocument.fileReviewSection.files.findIndex((ex: UploadStepModel) => {
        return ex.uploadedPartGuid === uploadedPartGuid;
      });
      //Update discrepancy status for quote assessment
      if(this.xaDocument?.options?.isQuoteAssessmentModule) {
        this.quoteManagementService.updateDiscrepancyFlag$.next(true);
      }
      this.deleteSelectedDocument(uploadStepModel, index);
      this.commonService.hideLoading();
    }, () => {
      this.commonService.hideLoading();
    })
  }

  /**
   * update deleted image
   */
  deleteSelectedDocument(currentStep: UploadStepModel, index: number): void {

    currentStep.contentType = '';
    currentStep.url = '';
    currentStep.uploadedGuid = '';
    currentStep.updatedDate = '';
    currentStep.uploadedDate = '';
    currentStep.uploadInProgress = false;
    currentStep.isSkipped = false;
    currentStep.isUploadSuccess = false;
    currentStep.uploadedPartGuid = '';
    currentStep.isUploadFailed = false;
    currentStep.imageFile = null;
    currentStep.image = null;
    currentStep.action = exportMapper(currentStep, this.xaDocument.fileReviewSection.actions );
    currentStep.uploadStatus = 'notUploaded';
    if (currentStep.stepName.indexOf('Additional') != -1) {
      currentStep.isAdditionalDocumentFromReviewPageOnly = true;
    }

    this.xaDocument.fileReviewSection.files[index] = { ...currentStep };
    const filesUploaded = this.xaDocument.fileReviewSection.files.filter(x=>x.stepName?.toLowerCase() !== 'consolidated document' && x.url);
    if(filesUploaded?.length === 0 && !this.consolidatedDocumentStep){
      this.consolidatedDocumentStep = { ...this.consolidatedStep };
    }
    this.xaDocument.fileReviewSection = { ...this.xaDocument.fileReviewSection };
    const hasURL = this.xaDocument.fileReviewSection.files.some(x=>x.url);
    if(!hasURL && !this.isCustomerLink){
      this.xaDocument.displayReviewSection = false;
      this.hideReviewSection = true;
      this.xaDocument.displaySelector = true;
      this.xaDocument = { ...this.xaDocument };
    }
  }
}
