import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Observable, lastValueFrom, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  appId: string = environment.platformConfiguration.ApplicationId;
  /**
   * constructor
   * @param http 
   */
  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {
  }

  /**
   * 
   */
  async validateAndPullConfigFile(): Promise<any> {
    try {
      const result = await this.getConfiguration();

      const versionNo = localStorage.getItem('versionNo');
      const languageFileName = result.data.files.find(x=>x.fileUrl.indexOf('English') > -1)?.fileUrl;
      localStorage.setItem('languageFile', languageFileName);
      localStorage.setItem('configFile', result.data.configurationFileURL);  
      localStorage.setItem('versionNo', `${result.data.tenantId}_${result.data.versionNo}`);
      const configData = localStorage.getItem('configData');
      const languageData = localStorage.getItem('languageData');
  
      if(versionNo != `${result.data.tenantId}_${result.data.versionNo}` || !configData) {
        const configData = await this.getConfigurationFile(localStorage.getItem('configFile'));
        localStorage.setItem('configData', JSON.stringify(configData));
        this.setConfigData(configData);
      } else {
        this.setConfigData(JSON.parse(configData));
      }
  
      if(versionNo != `${result.data.tenantId}_${result.data.versionNo}` || !languageData) {
        const languageData = await this.getTranslation();
        this.setTranslation(languageData);
      } else {        
        this.setTranslation(JSON.parse(languageData));
      }
  
      return of(true); 
    } catch (error) {
      return of(true);
    }
  }

  /**
   * 
   * @param configData 
   */
  setConfigData(configData: any):void {
    const colorList = configData.Categories.find(x => x.Name == 'Branding').Items
      .find(value => Object.keys(value)[0] == 'COLOR').COLOR;
  
    const filteredColorList = [ ...colorList.filter(x=> x.ItemName.indexOf('xa-') == 0) ];
  
    filteredColorList.forEach((element) => {
      document.documentElement.style.setProperty('--' + element.ItemName, element.ItemValue);
    });
  }

  /**
   * get icon data
   */
  getIconData(configData:any):any{
    const dynamicUrls = configData.Categories.find(x => x.Name?.toLowerCase() == 'branding').Items.find(value => Object.keys(value)[0] == 'DYNAMICURL').DYNAMICURL;   
    const storageUrl = dynamicUrls.find(x=>x.ItemName === 'Logo')?.value;
    const backgroundUrl = dynamicUrls.find(x=>x.ItemName === 'BackgroundImage')?.value;
    const appConfigs = configData.Categories.find(x => x.Name?.toLowerCase() == 'applicationconfigurations').Items.find(value => Object.keys(value)[0] == 'TEXT').TEXT;
    const iosLink = appConfigs.find(x=>x.ItemName === 'IosStoreLink');
    const platformConfig = appConfigs.find(x=>x.ItemName === 'ConfigurationStorageServer');

    let iconUrl = '';
    let backgroundImageUrl = '';
    let androidLinkUrl = '';
    let iosLinkUrl = '';
    let baseConfigUrl = '';

    if(platformConfig){
      baseConfigUrl = platformConfig.ItemValue;
    }

    if(storageUrl){
      const baseUrl = storageUrl.find(x=>x.ItemKey == 'Server')?.ItemValue;
      const icon = storageUrl.find(x=>x.ItemKey == 'imagepath')?.ItemValue;
      iconUrl = `${baseUrl}${icon}`;
    }
    if(backgroundUrl){
      const baseUrl = backgroundUrl.find(x=>x.ItemKey == 'Server')?.ItemValue;
      const icon = backgroundUrl.find(x=>x.ItemKey == 'imagepath')?.ItemValue;
      backgroundImageUrl = `${baseUrl}${icon}`;
    }
    const pageComponents = configData.Categories.find(x => x.Name?.toLowerCase() == 'branding').Items.find(value => Object.keys(value)[0] == 'PAGECOMPONENTS')?.PAGECOMPONENTS || [];  
    const androidLink = appConfigs.find(x=>x.ItemName === 'AndroidStorelink');
    if(androidLink){
      androidLinkUrl = androidLink.ItemValue;
    }

    if(iosLink){
      iosLinkUrl = iosLink.ItemValue;
    }
    return {
      iconUrl,
      backgroundImageUrl,
      androidLinkUrl,
      iosLinkUrl,
      baseConfigUrl,
      pageComponents
    }
  }

  /**
   * get translation landing
   */
  async setLandingPageTranslation(configData:any):Promise<void>{
    const files = configData.Categories.find(x => x.Name == 'Localization').Items.find(value => Object.keys(value)[0] == 'FILE').FILE;
    const languageFileObj = files.find(x=>x.ItemName?.toLowerCase().indexOf('englishresourcefile') > -1)?.value;
    const fileName = languageFileObj[0].ItemValue;
    if(fileName){
      const languageData = await this.getDefaultTranslation(fileName);
      const localLanguageData = await this.getLocalTranslation('en');
      const mergedLanguageData = { ...localLanguageData, ...languageData };
      this.translate.setTranslation('en', mergedLanguageData);
      this.translate.use('en');
    }
  }


  /**
   * 
   * @param domainId 
   * @returns 
   */
  getStepByDomainId(domainId: any):any {
    const configData:any = localStorage.getItem('configData');
    const branding = JSON.parse(configData)?.Categories
      .find(x => x.Name == 'Branding').Items
    if(branding) {
      const services = branding.find(value => Object.keys(value)[0] == 'SERVICES')?.SERVICES
      if(services){
        return services.find(x=>x.DomainID == domainId)?.value || [];
      }
      const workflow = branding.find(value => Object.keys(value)[0] == 'WORKFLOW')?.WORKFLOW
      if(workflow){
        return workflow.find(x=>x.DomainID == domainId)?.value || [];
      } 
    }
    return [];
  }

  /**
   * get ai analysis flags
   */
  getAIAnalysisFlags():any{
    const configData:any = localStorage.getItem('configData');
    const aiFlags = [ 'DamageAnalysis_DisplayType', 'DamageCategory_Display', 'AnalysisModification_Display' ];
    const damageFlags = JSON.parse(configData)?.Categories
      .find(x => x.Name == 'Branding').Items
      .find(value => Object.keys(value)[0] == 'TEXT')?.TEXT
      .filter(x=>aiFlags.includes(x.ItemName));
    const damageFlagMap:any = {};
    if(damageFlags && damageFlags?.length > 0){
      for(const flag of damageFlags){
        damageFlagMap[flag.ItemName] = flag.ItemValue;
      }
    }
    return isEmpty(damageFlagMap) ? null : damageFlagMap;
  }

  /**
   * get ai analysis flags
   */
  getCameraFlags():any{
    const configData:any = localStorage.getItem('configData');
    const cameraFlags = [ 'Camera_Internal', 'Camera_Orientation' ];
    const cameraFlagObj = JSON.parse(configData)?.Categories
      .find(x => x.Name == 'Branding').Items
      .find(value => Object.keys(value)[0] == 'TEXT')?.TEXT
      .filter(x=>cameraFlags.includes(x.ItemName));
    const cameraFlagMap:any = {};
    if(cameraFlagObj && cameraFlagObj?.length > 0){
      for(const flag of cameraFlagObj){
        cameraFlagMap[flag.ItemName] = flag.ItemValue;
      }
    }
    return cameraFlagMap;
  }

  /** 
   * get Config
   * @returns
   */
  async getConfiguration(): Promise<any> {
    const apiKey = environment.platformConfiguration.ApiKey;
    const user: any = await lastValueFrom(this.http.get(`${environment.autoCoreApi}/api/Users/user-Details`));
    const tenantApps: any = await lastValueFrom(this.http.get(`${environment.autoCoreApi}/api/applicationConfiguration/${user.data.tenantId}`))
    const webApp = tenantApps?.data?.find(x => x.applicationType === 'web');
    if(webApp) this.appId = webApp.applicationGUID;
    const headers = new HttpHeaders().append('ApiKey', apiKey)
      .append('X-Application-Id', this.appId);
    
    return lastValueFrom(this.http.get(`${environment.autoCoreApi}/api/PlatformConfiguration/`, { headers: headers }));
  }

  /**
   * get tenant configuration
   */
  getTenantConfiguration(tenantId:number): Observable<any> {
    const dateTime = dayjs(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss');
    const headers = new HttpHeaders().append('ApiKey', environment.platformConfiguration.ApiKey).append('X-Timestamp', dateTime)
      .append('X-Application-Id', environment.platformConfiguration.ApplicationId).append('X-Signature', this.generateSecureHeader(dateTime, environment.platformConfiguration.ApiKey));

    return this.http.get(`${environment.autoCoreApi}/api/PlatformConfiguration/tenant-Config/${tenantId}`, { headers: headers });
  }

  /**
   * get tenant configuration
   */
  getDefaultConfig(): Observable<any> {
    const dateTime = dayjs(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss');
    const headers = new HttpHeaders().append('ApiKey', environment.platformConfiguration.ApiKey).append('X-Timestamp', dateTime)
      .append('X-Application-Id', environment.platformConfiguration.ApplicationId).append('X-Signature', this.generateSecureHeader(dateTime, environment.platformConfiguration.ApiKey));

    return this.http.get(`${environment.autoCoreApi}/api/PlatformConfiguration/default-Config`, { headers: headers });
  }

  /** 
 * get getConfiguration File
 * @returns
 */
  async getConfigurationFile(filename): Promise<any> {
    const headers = new HttpHeaders().append('ApiKey', environment.platformConfiguration.ApiKey)
      .append('X-Application-Id', this.appId);
    return lastValueFrom(this.http.get(`${environment.autoCoreApi}/api/PlatformConfiguration/file/${filename}`, { headers: headers }));
  }

  /**
   * get default translation
   */
  async getDefaultTranslation(languageFileParam:string): Promise<any> {
    const dateTime = dayjs(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss');
    const headers = new HttpHeaders().append('ApiKey', environment.platformConfiguration.ApiKey).append('X-Timestamp', dateTime)
      .append('X-Application-Id', environment.platformConfiguration.ApplicationId).append('X-Signature', this.generateSecureHeader(dateTime, environment.platformConfiguration.ApiKey, languageFileParam));

    if (languageFileParam) {
      return lastValueFrom(this.http.get(`${environment.autoCoreApi}/api/PlatformConfiguration/default-configfile/${languageFileParam}`, { headers: headers }));
    }
  }

  /**
   * get form template instance data
   */
  getTemplateInstanceFormData(templatedUrl:string):Observable<any>{
    const url = `${environment.autoCoreApi}/${templatedUrl}`;
    return this.http.get(url);
  }

  /**
   * generate app key
   */
  generateAppKey(accountId:string):Observable<any>{
    return this.http.get(`${environment.autoCoreApi}/api/Token/create-code/${accountId}`);
  }

  /**
   * generate secure header
   */
  generateSecureHeader(currentDateTime:string, key:string, fileName?:string):string{
    const encryptionKey = `${environment.platformConfiguration.ApplicationId}${currentDateTime}${key}${fileName ?? ''}`;
    const hmac = CryptoJS.HmacSHA512(encryptionKey, environment.platformConfiguration.AppIdSign);
    const hex = hmac.toString(CryptoJS.enc.Hex);
    const hexadecimalNumber = hex.match(/.{1,2}/g)?.join('-').toUpperCase();
    return hexadecimalNumber || '';
  }

  /**
  * 
  * @param lang 
  * @returns 
  */
  async getTranslation(languageFileParam?:string): Promise<any> {
    const headers = new HttpHeaders().append('ApiKey', environment.platformConfiguration.ApiKey)
      .append('X-Application-Id', this.appId);

    const languageFile = localStorage.getItem('languageFile');
    if (languageFile || languageFileParam) {
      return lastValueFrom(this.http.get(`${environment.autoCoreApi}/api/PlatformConfiguration/file/${languageFileParam ?? languageFile}`, { headers: headers }));
    }
  }

  /**
  * 
  * @param lang 
  * @returns 
  */
  async getLocalTranslation(lang: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      })
    }
    return lastValueFrom(this.http.get(`assets/i18n/${lang}.json`, httpOptions));
  }

  /**
   * store language from local and server
   * @param langData 
   */
  async setTranslation(langData): Promise<any> {
    const localLanguageData = await this.getLocalTranslation('en');
    const mergedLanguageData = { ...localLanguageData, ...langData };
    localStorage.setItem('languageData', JSON.stringify(mergedLanguageData));
    this.translate.setTranslation('en', mergedLanguageData);
    this.translate.use('en');
  }

}