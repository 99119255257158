import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstimateService {

  /**
   * constructor
   * @param http
   */
  constructor(private readonly http: HttpClient) {
  }

  /**
   * get Addenda Estimate By Object Guid
   *
   * @param {string} objectGuid
   * @returns {Observable<any>}
   */
  getAddendaEstimateByObjectGuid(objectGuid: string, domainId: number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${objectGuid}/${domainId}`;
    return this.http.get(url)
  }

  /**
   * get Addenda Estimate By Object Guid and calculation type id
   *
   * @param {string} objectGuid
   * @param {number} calculationTypeId
   * @returns {Observable<any>}
   */
  getAddendaEstimateByObjectGuidAndMode(objectGuid: string, calculationTypeId: number, domainId: number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${objectGuid}/${domainId}/${calculationTypeId}`;
    return this.http.get(url)
  }

  /**
   * save Addenda Estimate
   *
   * @param {*} obj
   * @param {string} objectGuid
   * @param {number} calculationTypeId
   * @returns {Observable<any>}
   */
  saveAddendaEstimate(obj: any, objectGuid: string, calculationTypeId: number, domainId: number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${objectGuid}/${domainId}/${calculationTypeId}`;
    return this.http.post(url, obj)
  }

  /**
   * 
   * @param objectGuid 
   * @param domainId 
   * @param calculationTypeId 
   * @returns 
   */
  addOperationToEstimate(payload: any, objectGuid: string, domainId: number, calculationTypeId: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/createestimateitemfromoperation/${objectGuid}/${domainId}/${calculationTypeId}`;
    return this.http.post(url, payload);
  }

  /**
   * get DamageSeverities
   *
   * @returns {Observable<any>}
   */
  getDamageSeverities(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/DamageSeverities`;
    return this.http.get(url);
  }

  /**
   * get Vehicle Section
   *
   * @returns {Observable<any>}
   */
  getVehicleSection(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/vehicleSection`;
    return this.http.get(url);
  }

  /**
   * get Standard Parts odata
   *
   * @param {string} query
   * @returns {Observable<any>}
   */
  getStandardParts(query: string, BodyTypeId, VehicleSectionId): Observable<any> {
    const url = `${environment.autoApi}/odata/v1/StandardParts${query}`;
    const headers = new HttpHeaders({
      'BodyTypeId': BodyTypeId,
      'VehicleSectionId': VehicleSectionId
    });
    return this.http.get(url, { headers });
  }
  /**
   * getEstimateLog
   * @param {string} repairOrCaseGuid
   * @returns {Observable<any>}
   */
  updateEstimateV2(objectGuid, payload, domainId: number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${objectGuid}/${domainId}`;
    return this.http.put<any>(url, payload);
  }


  /**
   * getEstimateLog
   * @param {string} repairOrCaseGuid
   * @returns {Observable<any>}
   */
  markChargeUpSaleNotUpSale(repairGuid, payload, domainId: number = 3100): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${repairGuid}/${domainId}`;
    return this.http.patch<any>(url, payload);
  }
}
