<div class="header-div"
     [ngClass]="{'fx-row justify-space-between mar-30':footerOptions?.isClaimModule &&  currentPageName === 'photos' && !hideAI && photos?.length !== 0}">
    <div [ngClass]="{'fx-row justify-start gp-10': footerOptions?.isClaimModule}">
        <ng-container *ngIf="footerOptions?.isClaimModule && currentPageName === 'photos' && showAIButton && !showAdditionalAttachments">
            <xa-tabs [tabPosition]="'top'"
                     (tabChanged)="switchImageType($event)"
                     [hideContent]="true"
                     mode="button">
                <xa-tab *ngFor="let item of customerSurveyorMenus"
                        tabTitle="{{item.name | translate}}"
                        [active]="item?.active"
                        [disabled]="item?.disabled"></xa-tab>
            </xa-tabs>
        </ng-container>

        <ng-container *ngIf="showAdditionalAttachments">
            <div class="row-wrapper"
                 (click)="backToReview()">
                <div class="d-flex align-center">
                    <mat-icon svgIcon="arrow-back-left"
                              class="back-icon"
                              aria-hidden="false"
                              aria-label='back icon'></mat-icon>
                </div>
                <div class="title-wrapper title-text">
                    <h4>{{'back_to_review' | translate}}</h4>
                </div>
            </div>
        </ng-container>

        <chq-step-header [chqModel]="header"
                         [hideBack]="hideBack"
                         [hideHeader]="footerOptions?.showBack"
                         (back)="showAI($event)"
                         (deleteAllHandler)="handleDeleteAll();"
                         [hideDelete]="hideDelete || isRepairStage"
                         (uploadBackHandler)="goBackToSelection();"
                         [steps]="steps"
                         [backTitle]="backTitle"
                         *ngIf="!showAdditionalAttachments"
                         [ngClass]="footerOptions?.isClaimModule ? 'align-header-block': ''"></chq-step-header>
    </div>

    <ng-container *ngIf="footerOptions?.isClaimModule && !showAdditionalAttachments">
        <div class="button-wrapper button-claim-wrapper"
             *ngIf="photos?.length !== 0 && showAIButton && currentPageName === 'photos' && !hideAI">
            <div class="button-div">
                <chq-button [buttonModel]="fraudDetectionButton"
                            class="fraud-button"
                            *ngIf="showFraudDetectionButton && !isClaimSurveyor"></chq-button>
                <chq-button [buttonModel]="AIButton"
                            *ngIf="displayAIButton"></chq-button>
                <ng-container *chqClaimRoles="'claimManager'">
                    <xa-button type="secondary"
                               class="left"
                               *ngIf="showAdditionalSurveyorButton"
                               color="secondary"
                               (click)="showAdditionalSurveyorImages()">
                        <span> {{'additional_surveyor_images' | translate }}</span>
                    </xa-button>
                </ng-container>

            </div>

        </div>
    </ng-container>
</div>
<ng-content></ng-content>
<div class="image-preview row"
     *ngIf="photos?.length !== 0 && !showAdditionalAttachments">
    <div class="button-wrapper">
        <div class="button-div">
            <chq-button [buttonModel]="DownloadAllButton"
                        *ngIf="showAIButton && !(footerOptions?.hideDownloadButton)"></chq-button>
            <chq-button [buttonModel]="AIButton"
                        *ngIf="showAIButton && currentPageName === 'photos' && !hideAI && !footerOptions?.isClaimModule"></chq-button>
            <xa-button type="outline"
                       color="gray"
                       *ngIf="isQuoteAssessmentModule && currentPageName === 'photos'"
                       (click)="showInference()">
                <mat-icon svgIcon="ai-inference"></mat-icon>
                <span> {{'ai_inference' | translate }}</span>
            </xa-button>
        </div>

    </div>
    <span class="mandatory-index"
          *ngIf="isMandatory()">* mandatory fields</span>
    <div class="step-wrapper"
         [ngClass]="{'border-gray-dotted': !step.url && !step.image, 'col-6':((!isLandscape && currentPageName === 'photos') || currentPageName === 'document'),'document-mode':currentPageName === 'document' ,'col-4':(isLandscape && currentPageName === 'photos')} "
         *ngFor="let step of photos; let i = index">
        <div class="step-container"
             (click)="this.footerOptions.hideEdit?edit(step):''">
            <div class="step-desc">
                <div class="step-text">
                    <div class="step-title">
                        <label for=""
                               *ngIf="step.procedureSteps  === 'Bulk Upload Image'">{{step.procedureSteps}} <span class="mandatory"
                                  *ngIf="isStepMandatory(step)">&nbsp;*</span></label>
                        <label for=""
                               *ngIf="step.procedureSteps  !== 'Bulk Upload Image' && (step.stepName || step.parentName)">{{step.stepName.indexOf('Additional')
                            === -1 && step.parentName
                            ?(step.parentName):step.stepName}} <span class="mandatory"
                                  *ngIf="isStepMandatory(step)">&nbsp;*</span></label>
                        <mat-icon [svgIcon]="'clock'"
                                  *ngIf="footerOptions?.showBack && step.analysisStatus ==='Analysis in Progress' else navail"></mat-icon>
                        <ng-template #navail>
                            <mat-icon *ngIf="(footerOptions?.showBack  && step.analysisStatus ==='Analysis Failed') else checked"
                                      [svgIcon]="'xmark'"
                                      aria-hidden="false"
                                      aria-label="checked icon"></mat-icon>
                        </ng-template>
                        <ng-template #checked>
                            <mat-icon *ngIf="(step.uploadStatus ==='success' || step.url) else unchecked"
                                      [svgIcon]="'checked'"
                                      aria-hidden="false"
                                      aria-label="checked icon"></mat-icon>
                        </ng-template>

                        <ng-template #unchecked>
                            <mat-icon [svgIcon]="'unchecked'"
                                      aria-hidden="false"
                                      aria-label="unchecked icon"></mat-icon>
                        </ng-template>
                    </div>
                    <div class="step-title ai-status-text"
                         [ngClass]="{'justify-end':step.stepName.indexOf('Additional') !== -1 &&  currentPageName !== 'photos' }">
                        <span *ngIf="footerOptions?.showBack">{{step.analysisStatus ==='Analysis Failed' ? 'AI analysis not available':(step.analysisStatus ==='Analysis in Progress'?'Analyzing':
                            'Analyzed')}}</span>
                        <span *ngIf="!footerOptions?.showBack && inspectionId">{{(step.uploadInProgress
                            ? 'upload_inprogress' : (step.isUploadSuccess ? 'upload_successfully': 'not_uploaded')) | translate}}</span>
                        <span *ngIf="!step.isAdditionalDocumentFromReviewPageOnly && step.stepName.indexOf('Additional') === -1 &&
                        !inspectionId"> {{step.isFront?'Front':'Back'}}</span>
                        <span>{{( ( step.updatedDate && step.updatedDate !== '0001-01-01T00:00:00' )?
                            step.updatedDate:step.uploadedDate) | date:'dd/MM/yyyy HH:mm aa'}}</span>
                        <span *ngIf="step.isAdditionalDocumentFromReviewPageOnly && step.stepName.indexOf('Additional') !== -1">
                            &nbsp;</span>
                    </div>

                </div>
            </div>
            <div class="step-image">
                <ng-container *ngIf="step.contentType !=='application/pdf'">
                    <img alt=""
                         *ngIf="step.image else serverImage"
                         (error)="setImageLoading(step.index + step?.stepName);"
                         (click)="imageDialog('',step?.image,step.procedureSteps  === 'Bulk Upload Image' ? step.procedureSteps: step.stepName);"
                         (load)="setImageLoading(step.index + step?.stepName)"
                         [src]='step.image' />
                    <ng-template #serverImage>
                        <img alt=""
                             *ngIf="(step.thumbnailUrl || step.url) else sampleImage"
                             (error)="setImageLoading(step.index + step?.stepName);"
                             (click)="imageDialog('',step?.url, step.procedureSteps  === 'Bulk Upload Image' ? step.procedureSteps: step.stepName);"
                             (load)="setImageLoading(step.index + step?.stepName)"
                             [src]='step?.thumbnailUrl ?? step.url' />
                    </ng-template>
                    <ng-template #sampleImage>
                        <img alt=""
                             [src]="step.placeHolderUrl || '/assets/icons/additional-image.svg'"
                             (error)="setImageLoading(step.index + step?.stepName);"
                             (load)="setImageLoading(step.index + step?.stepName);" />
                    </ng-template>

                    <chq-custom-loader [showLoading]="(step?.uploadInProgress && !footerOptions?.showBack) || !imageLoading[step.index + step?.stepName]"></chq-custom-loader>
                </ng-container>
                <ng-container *ngIf="step.contentType ==='application/pdf'">
                    <mat-icon class='pdf-icon'
                              [svgIcon]="'pdf-file'"
                              aria-hidden="false"
                              aria-label="pdf-file icon"></mat-icon>
                </ng-container>
            </div>
            <ng-container *ngIf="!footerOptions?.isClaimModule; else claimModuleSection">
                <ng-container *chqPermission="!footerOptions?.section ? 'newBooking' : 'repairStages'">
                    <div class="step-action-icon"
                         *ngIf="(this.footerOptions?.currentStatus?.toLowerCase() !== 'complete') || fromRepairView"
                         [ngClass]="{'delete-only':step.isMassUpload || (step.stepName.indexOf('Additional') !== -1 && !step.image && !step.url )}">
                        <div class="comment-div"
                             *ngIf="currentStatus && (step.url || step.uploadedPartGuid) && !footerOptions?.showBack && header?.isRepairModule && currentPageName === 'photos'">
                            <mat-icon [svgIcon]="'comment'"
                                      [ngClass]="{'icon-disabled': (!step.image && !step.url) || uploading, 'icon-hide': !step.image && !step.url }"
                                      class="delete-icon border-light-gray"
                                      aria-hidden="false"
                                      (click)="$event.stopPropagation();showCommentDialog(step);"
                                      aria-label="unchecked icon">
                            </mat-icon>
                            <div class="red-icon"
                                 *ngIf="step?.inspectionItem?.inspectionNote && step?.inspectionItem?.inspectionNote[0]?.text">
                            </div>
                        </div>
                        <mat-icon [svgIcon]="'download-arrow'"
                                  class="download-icon border-light-gray"
                                  *ngIf="((fromRepairView && !step.isAdditionalDocumentFromReviewPageOnly) || (currentStatus && !step.isAdditionalDocumentFromReviewPageOnly  && !this.commonService.isViewOnly && step.procedureSteps  !== 'Bulk Upload Image' && !step.isMassUpload && !footerOptions.hideEdit && (step.url || step.uploadedPartGuid)))"
                                  aria-hidden="false"
                                  (click)="download(step);"
                                  aria-label="edit icon"></mat-icon>
                        <mat-icon [svgIcon]="'edit'"
                                  class="edit-icon border-light-gray"
                                  *ngIf="currentStatus && !step.isAdditionalDocumentFromReviewPageOnly && !missing && !this.commonService.isViewOnly && step.procedureSteps  !== 'Bulk Upload Image' && !step.isMassUpload && !footerOptions.hideEdit && (step.url || step.uploadedPartGuid)"
                                  aria-hidden="false"
                                  (click)="edit(step);"
                                  aria-label="edit icon"></mat-icon>
                        <chq-button [buttonModel]="uploadButtonModel"
                                    *ngIf="(currentStatus && step.analysisStatus !=='Analysis in Progress' && step.analysisStatus !=='Analysis Failed'  && !this.commonService.isViewOnly  && (!step.url && !step.uploadedPartGuid && !step?.uploadInProgress))"
                                    aria-hidden="false"
                                    (click)="$event.stopPropagation();isCameraEnabled?upload(step,'integrated'):upload(step);"
                                    aria-label="edit icon"></chq-button>
                        <mat-icon [svgIcon]="'delete'"
                                  [ngClass]="{'icon-disabled': (!step.image && !step.url) || uploading, 'icon-hide': step.isAdditionalDocumentFromReviewPageOnly && !step.image && !step.url }"
                                  class="delete-icon border-danger"
                                  aria-hidden="false"
                                  *ngIf="currentStatus && (step.url || step.uploadedPartGuid)  && !this.commonService.isViewOnly"
                                  (click)="$event.stopPropagation();deleteUpload(step);"
                                  aria-label="unchecked icon"></mat-icon>

                    </div>
                </ng-container>
            </ng-container>
            <ng-template #claimModuleSection>

                <div class="step-action-icon"
                     [ngClass]="{'delete-only':step.isMassUpload || (step.stepName.indexOf('Additional') !== -1 && !step.image && !step.url )}">
                    <div class="comment-div"
                         *ngIf=" !footerOptions?.showBack && currentPageName === 'photos' && commonService.fnolId && step.inspectionItem?.inspectionNote?.length > 0">
                        <mat-icon [svgIcon]="'comment'"
                                  class="delete-icon border-light-gray"
                                  aria-hidden="false"
                                  (click)="$event.stopPropagation();showCommentDialog(step, 'claim');"
                                  aria-label="unchecked icon">
                        </mat-icon>
                    </div>
                    <ng-container *ngIf="!isClaimSurveyor">
                        <mat-icon [svgIcon]="'download-arrow'"
                                  class="download-icon border-light-gray"
                                  *ngIf="currentStatus && !step.isAdditionalDocumentFromReviewPageOnly  && !this.commonService.isViewOnly && step.procedureSteps  !== 'Bulk Upload Image' && !step.isMassUpload && !footerOptions.hideEdit && (step.url || step.uploadedPartGuid)"
                                  aria-hidden="false"
                                  (click)="download(step);"
                                  aria-label="edit icon"></mat-icon>
                        <mat-icon [svgIcon]="'edit'"
                                  class="edit-icon border-light-gray"
                                  *ngIf="!step.isLocked && showEditDeleteButtonsClaim && !this.commonService.isViewOnly && currentStatus && !step.isAdditionalDocumentFromReviewPageOnly && step.procedureSteps  !== 'Bulk Upload Image' && !step.isMassUpload && !footerOptions.hideEdit && (step.url || step.uploadedPartGuid)"
                                  aria-hidden="false"
                                  (click)="editImageClaim(step, 'edit');"
                                  aria-label="edit icon"></mat-icon>
                        <chq-button [buttonModel]="uploadButtonModel"
                                    *ngIf="(currentStatus && !this.commonService.isViewOnly && step.analysisStatus !=='Analysis in Progress' && step.analysisStatus !=='Analysis Failed'  && (!step.url && !step.uploadedPartGuid && !step?.uploadInProgress))"
                                    aria-hidden="false"
                                    (click)="$event.stopPropagation();editImageClaim(step, 'upload');"
                                    aria-label="edit icon"></chq-button>
                        <mat-icon [svgIcon]="'delete'"
                                  [ngClass]="{'icon-disabled': (!step.image && !step.url) || uploading, 'icon-hide': step.isAdditionalDocumentFromReviewPageOnly && !step.image && !step.url }"
                                  class="delete-icon border-danger"
                                  aria-hidden="false"
                                  *ngIf="!step.isLocked && showEditDeleteButtonsClaim && currentStatus && !this.commonService.isViewOnly &&  (step.url || step.uploadedPartGuid)"
                                  (click)="$event.stopPropagation();editImageClaim(step, 'delete');"
                                  aria-label="unchecked icon"></mat-icon>
                    </ng-container>

                </div>
            </ng-template>
        </div>
    </div>
    <div class="footer-full-wrapper">
        <div class="footer-image-wrapper">
            <div class="image-wrapper">
                <img alt=""
                     src="assets/img/{{ + (header?.isFnolModule || header?.isQuoteAssessmentModule) ? 'addenda.png': ((header?.isRepairModule && !fromRepairView) ?'addenda-repair.png': (header?.isClaimModule || fromRepairView) ? 'addenda-claims.png' : 'addenda-quote.png')}}" />
            </div>

        </div>
        <div class="footer-button-wrapper"
             *chqPermission="!footerOptions?.section ? 'newBooking' : 'repairStages'">

            <xa-button type="outline"
                       color="gray"
                       (click)="commonService.requestCustomer.next(true)"
                       *ngIf="sendRequest === 1 || sendRequest === 2">
                <span *ngIf="sendRequest === 2"> {{'send_request' | translate }}</span>
                <span *ngIf="sendRequest === 1"> {{'request_missing_information' | translate }}</span>
            </xa-button>

            <chq-button [buttonModel]="cancelButtonModel"
                        *ngIf="showDelete && header?.isRepairModule && !this.commonService.isViewOnly && !fromRepairView"></chq-button>
            <chq-button [buttonModel]="backButtonModel"
                        *ngIf="footerOptions?.showUploadOption === true && bothOptionEnabled && !footerOptions?.showBack && !header?.isRepairModule  && !this.commonService.isViewOnly"></chq-button>
            <chq-button *ngIf="currentStatus && footerOptions?.showBack && unAnalysedFiles  && !fromRepairView"
                        [buttonModel]="retryButtonModel"></chq-button>
            <chq-button *ngIf="footerOptions?.showBack && analysedFiles"
                        [buttonModel]="pdfButtonModel"></chq-button>
            <chq-button *ngIf="!footerOptions?.hideNext && !header?.isRepairModule && !isRepairStage && !this.commonService.isViewOnly && !(showAiGenerateEstimate && footerOptions?.showBack)"
                        [buttonModel]="nextButtonModel"></chq-button>
            <chq-button *ngIf="(header?.isRepairModule || repairOrCaseData?.caseGuid) && !isRepairStage && currentPageName === 'photos'  && !this.commonService.isViewOnly && !notAnalysedFiles && footerOptions?.showBack && showAiGenerateEstimate && !footerOptions?.aiEstimationStatus"
                        [buttonModel]="aiGenerateEstimate"
                        class="ai-generate-estimate"></chq-button>
            <chq-button *ngIf="header?.isRepairModule && !isRepairStage && currentPageName === 'photos'  && !this.commonService.isViewOnly && !footerOptions?.showBack"
                        [buttonModel]="repairEstimateModel"></chq-button>
            <ng-container *chqPermission="'newBooking'">
                <chq-button [buttonModel]="notifyButtonModel"
                            *ngIf="showNotify"></chq-button>
            </ng-container>
            <ng-container *ngIf="footerOptions?.isClaimModule && !this.commonService.isViewOnly">
                <ng-container *ngIf="canShowApproveRejectButton">
                    <chq-button [buttonModel]="rejectButtonModel"
                                *chqClaimRoles="'claimManager'"
                                (click)="openRejectClaimPopup()"></chq-button>
                    <ng-container *ngIf="footerOptions?.currentStatus !== claimStatus.EstimateRejected">
                        <chq-button [buttonModel]="approveButtonModel"
                                    *chqClaimRoles="'claimManager'"></chq-button>
                    </ng-container>
                </ng-container>
                <ng-container *chqClaimRoles="'claimChecker'">
                    <chq-button [buttonModel]="submitForApprovalButtonModel"
                                [ngClass]="displaySubmitForApprovalButton() ? '' : 'button-disabled'"
                                *ngIf="currentPageName === 'photos'
                    && (footerOptions?.currentStatus === claimStatus.AssignedBackFromApproval || footerOptions?.currentStatus === claimStatus.ReviewInProgress || footerOptions?.currentStatus === claimStatus.UploadPhotos || footerOptions?.currentStatus === claimStatus.UploadDocuments || footerOptions?.currentStatus === claimStatus.DetailsUpdated)"></chq-button>
                </ng-container>
                <ng-container *chqClaimRoles="'claimMaker'">
                    <chq-button [buttonModel]="submitForReviewModel"
                                [ngClass]="displaySubmitForReview() ? '' : 'button-disabled'"
                                *ngIf="currentPageName === 'photos'
                        && (footerOptions?.currentStatus === claimStatus.AssignedBackFromReview || footerOptions?.currentStatus === claimStatus.UploadPhotos || footerOptions?.currentStatus === claimStatus.UploadDocuments || footerOptions?.currentStatus === claimStatus.DetailsUpdated)"></chq-button>
                </ng-container>

                <ng-container *ngIf="showAssignBackButton">
                    <chq-button [buttonModel]="assignBackApprovalButtonModel"
                                *chqClaimRoles="'claimAssignBack'"></chq-button>
                </ng-container>
                <ng-container *ngIf="footerOptions?.currentStatus === claimStatus.SettlementInitiated">
                    <chq-button [buttonModel]="settleClaimButtonModel"
                                *chqClaimRoles="'claimManager'"></chq-button>
                </ng-container>
            </ng-container>
        </div>
        <ng-container *ngIf="header?.isRepairModule && !footerOptions?.section && !fromRepairView">
            <div class="footer-button-wrapper"
                 *chqPermission="'technician'">
                <chq-button *ngIf="footerOptions?.showBack && analysedFiles"
                            [buttonModel]="pdfButtonModel"></chq-button>
            </div>
        </ng-container>
    </div>
</div>
<div *ngIf="photos?.length === 0 && footerOptions?.showBack && !commonService.isLoading"
     class="no-images-container">
    <label for=""
           class="no-data">Sorry, no vehicle images found for AI analysis.</label>
</div>

<div class="image-preview row"
     *ngIf="showAdditionalAttachments">
    <div class="step-wrapper"
         [ngClass]="{ 'col-6': !isLandscape ,'col-4': isLandscape}"
         *ngFor="let step of surveyorAttachments; let i = index">
        <div class="step-container">
            <div class="step-desc">
                <div class="step-text">
                    <div class="step-title">
                        <label for="">Additional Attachments</label>
                        <mat-icon [svgIcon]="'checked'"
                                  aria-hidden="false"
                                  aria-label="checked icon"></mat-icon>
                    </div>
                </div>
            </div>
            <div class="step-image">
                <img alt=""
                     [src]='step.url'
                     (click)="imageDialog('', step.url, 'Additional Attachments')"
                     *ngIf="step.ext !== 'pdf'" />
                <mat-icon class='pdf-icon'
                          [svgIcon]="'pdf-file'"
                          aria-hidden="false"
                          aria-label="pdf-file icon"
                          *ngIf="step.ext === 'pdf'">
                </mat-icon>
            </div>
            <div class="step-action-icon">
                <mat-icon [svgIcon]="'download-arrow'"
                          class="download-icon border-light-gray"
                          aria-hidden="false"
                          (click)="download(step);"
                          aria-label="edit icon"></mat-icon>

            </div>
        </div>
    </div>
</div>
