<div class="documents"
     *ngIf="mediaId">
    <label class="custom-label"
           for="">{{'document' | translate}}</label>
    <div class="document-wrapper">
        <div class="document"
             *ngIf="file?.length > 0 || existingDocs?.length">
            <span class="doc"
                  (click)="downloadMediaPart(doc);"
                  *ngFor="let doc of existingDocs; let i = index;">
                {{doc.Name}}
                <mat-icon svgIcon="cross"
                          *ngIf="doc.MediaPartGuid"
                          (click)="$event.stopPropagation();this.handleFileRemove(i, doc)"></mat-icon>
            </span>
            <span class="doc"
                  (click)="download(doc);"
                  *ngFor="let doc of file; let i = index;">
                {{doc.name}}
                <mat-icon svgIcon="cross"
                          (click)="$event.stopPropagation();handleFileRemove(i)"></mat-icon>
            </span>
        </div>
        <div class="document-btn">
            <xa-select [config]="existingDocumentConfig"
                       [options]="existingDocumentsOptions"
                       (selection)="selection($event)"></xa-select>
            <span class="or-span"
                  *ngIf="additionalPartMediaObj">or</span>
            <xa-button class="add-file"
                       type="basic"
                       (click)="handleAddFileClick()"
                       *ngIf="additionalPartMediaObj">
                {{'upload_document' | translate}}
            </xa-button>
            <input hidden
                   type="file"
                   #uploader
                   (change)="handleUpload($event)"
                   accept="image/png, image/jpg, image/jpeg, application/pdf">
        </div>
    </div>
</div>