import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { firstValueFrom } from 'rxjs';
import { RepairEstimateOperationTableConfig } from 'src/app/config/display/repair-estimate-operation-table-config';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { EstimateService } from 'src/app/services/estimate/estimate.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { RepairEstimateOperationService } from 'src/app/services/repair-estimate-operation/repair-estimate-operation.service';
import { ApiPagination, defaultPagination, PaginateInfo } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { CellClickedCallback, TableCellData, TableConfig, TableRowData, XaTable } from 'src/app/widgets/xa-table/xa-table.component';

@Component({
  selector: 'app-operation-list',
  templateUrl: './operation-list.component.html',
  styleUrls: [ './operation-list.component.scss' ]
})

export class OperationListComponent implements OnInit {
  public tableConfig: TableConfig;
  public tableData: any = [];
  public pageChanged: boolean = false;
  public selectedRows: Array<string> = [];
  public pagination: ApiPagination = defaultPagination;
  public static currentPage: number = 1;
  public showData = false;
  private readonly configData: any = RepairEstimateOperationTableConfig;
  @ViewChild('operationTable') operationTable: XaTable;
  @Output() handleEditRow: EventEmitter<any> = new EventEmitter();
  @Output() cancelDialog: EventEmitter<any> = new EventEmitter();
  @Input() objectGuid: any;
  @Input() domainId: any;
  @Input() estimationMode: any

  public refreshButtonModel: ChqWidgetsButtonModel = {
    label: 'refresh',
    type: 'outline',
    icon: ''
  }

  public addButtonModel: ChqWidgetsButtonModel = {
    label: 'add',
    type: 'disabled',
    icon: ''
  };


  i18n: Localization;

  /**
    Constructor for the RepairEstimateOperationService class.
    @constructor
    @param {RepairEstimateOperationService} repairEstimateOperationService - An instance of the RepairEstimateOperationService class.
    @param {MonitorService} monitorService - An instance of the MonitorService class.
    @param {CommonService} commonService - An instance of the CommonService class.
  */
  constructor(
    public monitorService: MonitorService,
    private readonly commonService: CommonService,
    private readonly estimateService: EstimateService,
    private readonly repairEstimateOperationService: RepairEstimateOperationService
  ) {
    this.i18n = this.commonService.geti18nInfo();
  }

  /**
    Lifecycle hook that is called when the component is initialized.
    It sets the table configuration, initializes the pagination object, and calls the getOperations() method.
  */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'OperationListComponent', 'addenda-repair' ]);
    this.tableConfig = this.configData;
    this.showData = false;
    this.pagination = {
      ...this.pagination, ...{
        pageSize: 10,
        count: 0,
        currentPage: OperationListComponent.currentPage,
        orderBy: 'Id',
        sortDirection: 'desc'
      }
    };
    this.getOperations();
  }

  /**
    Retrieves a list of operations from the repair estimate operation service.
    @returns {Promise} A promise that resolves with the list of operations.
  */
  public async getOperations(): Promise<any> {
    this.commonService.showLoading();
    this.monitorService.logEvent('getOperations', [ 'OperationListComponent', 'addenda-repair' ]);
    return firstValueFrom(this.repairEstimateOperationService.getOperations(this.pagination)).then((response) => {
      if (response.value) {
        this.tableData = response.value;
        this.showData = true;
        this.pagination = { ...this.pagination, count: response['@odata.count'] };
        this.changeAddButtonState();
        setTimeout(() => {
          const rowsToSelect = [];
          this.selectedRows.forEach((selectedId) => {
            const selectedRow = response.value.find((row: any) => row.OperationGuid == selectedId);
            if (selectedRow) {
              rowsToSelect.push({ id: 'Selection', result: { value: selectedId } })
            }
          });
          if (rowsToSelect.length > 0) this.operationTable.selectMultiple(rowsToSelect);
        });
      }
      this.commonService.hideLoading();

    }).catch((error) => {
      this.commonService.hideLoading();
      this.monitorService.logException(error, SeverityLevel.Error);
    });
  }

  /**
    Handles pagination change events.
    @param {PaginateInfo} event - The pagination information object.
    @returns {void}
  */
  handlePaginationChange(event: PaginateInfo): void {
    const pagination: ApiPagination = {
      pageSize: event.pageSize,
      offset: event.offset,
      currentPage: event.currentPage
    };

    this.pagination.currentPage = event.currentPage;
    this.pagination = { ...this.pagination, ...pagination };

    this.pageChanged = true;
    this.getOperations();
  }

  /**
    Handles a cell click event.
    @param {TableRowData} row - The row data.
    @param {Partial} column - The column data.
    @returns {void}
  */

  handleCellClick: CellClickedCallback = (row: TableRowData, column: Partial<TableCellData>): void => {
    if (column.id === 'Selection') {
      if (this.selectedRows.indexOf(row[0]?.result?.value) !== -1) {
        this.selectedRows.splice(this.selectedRows.indexOf(row[0]?.result?.value), 1)
      } else {
        this.selectedRows.push(row[0]?.result?.value)
      }
      this.monitorService.logEvent('handleCellClick', [ 'OperationListComponent', 'addenda-repair' ], {
        selectedRows: this.selectedRows
      });
      this.changeAddButtonState();
    } else if (column.id === 'DeleteAction') {
      this.deleteRow(row[0]?.result?.value)
    } else if (column.id === 'EditAction') {
      this.editRow(row[0]?.result?.value)
    }
  };

  /**
    Deletes a row from the operation list.
    @param {string} operationId - The ID of the operation to be deleted.
    @returns {void}
  */
  deleteRow(operationId: string): void {
    this.commonService.openDeleteDialog()
      .afterClosed().subscribe((data) => {
        if (data) {
          this.commonService.showLoading();
          this.monitorService.logEvent('deleteRow', [ 'OperationListComponent', 'addenda-repair' ], {
            selectedRows: this.selectedRows
          });
          firstValueFrom(this.repairEstimateOperationService.deleteOperation(operationId)).then((response) => {
            if (response?.message) this.commonService.showInfoToast(5000, response.message)
            if (this.selectedRows.indexOf(operationId) !== -1) {
              this.selectedRows.splice(this.selectedRows.indexOf(operationId), 1)
            }
            this.getOperations();
          }).catch((error) => {
            this.commonService.hideLoading();
            this.monitorService.logException(error, SeverityLevel.Error);
          });
        }
      });
  }

  /**
    Edits a row in the table based on the provided operation ID.
    @param {string} operationId - The ID of the operation to edit.
    @returns {void}
  */
  editRow(operationId: string): void {
    if (operationId) {
      const rawToEdit = this.tableData.find((row: any) => row.OperationGuid == operationId);
      if (rawToEdit) this.handleEditRow.emit(rawToEdit);
    }
  }

  /**
    Changes the state of the add button based on the number of selected rows.
    If there are no selected rows, the button is disabled. Otherwise, it is set to primary.
    @returns {void}
  */
  changeAddButtonState = (): void => {
    if (this.selectedRows.length > 0) this.addButtonModel.type = 'primary'
    else this.addButtonModel.type = 'disabled'
  }

  /**
    Handles the refresh operation list event.
    @param {Event} event - The event object.
    @returns {void}
  */
  handleRefreshOperationList = (event: Event): void => {
    this.selectedRows = [];
    this.operationTable?.selection?.clear()
    this.getOperations();
  };

  /**
    Handles the click event for the add button.
    @param {Event} event - The event object.
    @returns {void}
  */
  handleAddClick = (event: Event): void => {
    if (this.selectedRows.length === 0) {
      return
    }
    this.monitorService.logEvent('handleAddClick', [ 'OperationListComponent', 'addenda-repair' ], {
      objectGuid: this.objectGuid,
      operation: this.selectedRows
    });
    this.commonService.showLoading();
    this.estimateService.addOperationToEstimate(this.selectedRows, this.objectGuid, this.domainId, this.estimationMode).subscribe({
      next: (result: any) => {
        if (result) {
          this.cancelDialog.emit(true);
        }
        this.commonService.hideLoading();
      },
      error: (err) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      }
    });
  }
}
