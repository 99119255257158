<div class="submit-info-page-container">
    <div class="container-width">
        <div class="intro-section"
             [ngClass]="{'ios' : platform.IOS}"
             *ngIf="!showStepperScreen && !alreadySubmitted && !isSubmitted">
            <div class="text-center">
                <img alt="addenda-logo"
                     src="assets/img/addenda.png">
                <div class="logo-border"></div>
                <h3 class="heading">{{'welcome_to_addenda' | translate}}</h3>
                <p class="sub-heading">{{'customer_journey_welcome_screen_subheading' | translate}}</p>
            </div>
            <div class="card"
                 *ngFor="let step of requestedSteps; index as i">
                <div class="step-no">{{'step' | translate }} {{i+1}}</div>
                <div class="step-info">{{step.text | translate}}
                    <ul *ngIf="step.subStep?.length > 0">
                        <li *ngFor="let subSep of step.subStep"><span class="card-value">{{subSep.name}}</span></li>
                    </ul>
                </div>
            </div>
            <div class="text-center">
                <xa-button size="lg"
                           (click)="goToStepper();">{{'lets_get_started' | translate}}</xa-button>
            </div>
        </div>
        <div *ngIf="showStepperScreen">
            <div class="header">
                <div class="back"
                     (click)="handleBackAction();"><span class="back-arrow"><mat-icon [svgIcon]="'left-arrow-round-filled'"></mat-icon></span>{{'back' | translate}}</div>
                <div class="back"
                     *ngIf="mediaDocumentUpload && !mediaDocumentUpload?.currentStep?.mandatory && mediaDocumentUpload?.files?.length === 0"
                     (click)="handleSkip();"><span class="back-arrow">{{'skip' | translate}}</span></div>
            </div>

            <mat-stepper #stepper
                         [linear]="true">
                <ng-template matStepperIcon="edit">
                    <mat-icon [svgIcon]="'check'"></mat-icon>
                </ng-template>
                <mat-step *ngFor="let step of requestedSteps; index as i"
                          [label]="step.stepperTitle"
                          [completed]="step.completed">
                    <div class="review-section"
                         *ngIf="step.step === 'review'">
                        <div class="scroll-section"
                             [ngClass]="{'ios' : platform.IOS}">
                            <div class="text-center page-into">{{'please_preview_your_details_before_uploading' | translate}}</div>
                            <div class="card mt-5">
                                <h5 class="card-heading">{{'customer_details' | translate}}</h5>
                                <div class="row">
                                    <div class="col-6">
                                        <label for=""
                                               class="card-label">{{'name' | translate}}</label>
                                        <div class="card-value">{{customerName}}</div>
                                    </div>
                                    <div class="col-6">
                                        <label for=""
                                               class="card-label">{{'email_id' | translate}}</label>
                                        <div class="card-value">{{caseDetails?.driverDetails?.email}}</div>
                                    </div>
                                    <div class="col-6">
                                        <label for=""
                                               class="card-label">{{'phone_number' | translate}}</label>
                                        <div class="card-value">{{customerMobileNo}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-5">
                                <h5 class="card-heading">{{'vehicle_detail' | translate}}</h5>
                                <div class="row">
                                    <div class="col-6">
                                        <label for=""
                                               class="card-label">{{'make' | translate}}</label>
                                        <div class="card-value">{{caseDetails?.vehicleMetaData?.make}}</div>
                                    </div>
                                    <div class="col-6">
                                        <label for=""
                                               class="card-label">{{'model' | translate}}</label>
                                        <div class="card-value">{{caseDetails?.vehicleMetaData?.model}}</div>
                                    </div>
                                    <div class="col-6">
                                        <label for=""
                                               class="card-label">{{'type' | translate}}</label>
                                        <div class="card-value">{{caseDetails?.vehicleMetaData?.vehicleType}}</div>
                                    </div>
                                    <div class="col-6">
                                        <label for=""
                                               class="card-label">{{'vin' | translate}}</label>
                                        <div class="card-value">{{caseDetails?.vehicleMetaData?.vinNum}}</div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngFor="let step of requestedSteps;let i = index;">
                                <div class="card mt-5"
                                     *ngIf="step.step !== 'review'">
                                    <div class="d-flex">
                                        <h5 class="card-heading">{{step.reviewScreenText}}</h5>
                                        <mat-icon [svgIcon]="'edit'"
                                                  (click)="goToStep(i);"
                                                  class="ml-auto edit-icon"></mat-icon>
                                    </div>
                                    <div *ngIf="step?.subStep?.length > 0">
                                        <ul>
                                            <li *ngFor="let subStep of step?.subStep"
                                                [ngClass]="{'hasImage':hasValue(subStep.name,step.step)}"><span class="card-value">{{subStep.name}}</span></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="step.step === 'video'">
                                        <ul>
                                            <li [ngClass]="{'hasImage':hasValue('video','video')}"><span class="card-value">{{'inspection_video'| translate}}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="text-center">
                            <xa-button size="lg"
                                       (click)="submitForm();">{{'submit'| translate}}</xa-button>
                        </div>
                    </div>
                    <div class="text-center"
                         *ngIf="step.step === 'document'">
                        <media-upload title="start_document_title"
                                      #mediaDocumentUpload
                                      [hidden]="this.activeDocumentSteps?.length === 0"
                                      [name]="this.activeDocumentSteps[activeChildDocumentStepIndex]?.stepName"
                                      buttonText="upload_document_btn"
                                      reviewTitle="documents_review"
                                      [image]="this.activeDocumentSteps[activeChildDocumentStepIndex]?.placeHolderUrl"
                                      module="document"
                                      [objectId]="objectId"
                                      [domainID]="domainId"
                                      [hideOutlineImg]="true"
                                      [steps]="this.activeDocumentSteps"
                                      [options]="documentOptions"
                                      (nextStep)="handleDocumentNext(i);"
                                      (documentStepsLoaded)="onDocumentLoad($event);"
                                      (documentFileUploaded)="handleActiveDocumentChange();"
                                      (fileUploaded)="handleImageChange($event,i);"
                                      subtitle="{{'start_document_description' | translate:{stepName: this.activeDocumentSteps[activeChildDocumentStepIndex]?.stepName} }}">
                            <div class="doc-description">
                                <span>{{'max_file_extension' | translate:{ fileSize: this.activeDocumentSteps[activeChildDocumentStepIndex]?.sizeLimit/(1024 * 1024) } }}</span>
                                <span>{{'supported_formats' | translate:{ extensions: this.activeDocumentSteps[activeChildDocumentStepIndex]?.allowExtension } }}</span>
                            </div>
                        </media-upload>
                    </div>
                    <div class="text-center"
                         *ngIf="step.step === 'photo'">
                        <media-upload title="capture_image_title"
                                      name="photos"
                                      buttonText="camera_image"
                                      reviewTitle="images_next"
                                      module="image"
                                      [steps]="this.steps"
                                      (nextStep)="nextStep(i);"
                                      (fileUploaded)="handleImageChange($event,i);"
                                      subtitle="lets_upload">
                            <div class="video-description">
                                <ul>
                                    <li>{{'capture_angles' | translate:{ sides: (sideCount || '0'), steps: sideNames } }}</li>
                                </ul>
                            </div>
                        </media-upload>
                    </div>
                    <div class="text-center"
                         *ngIf="step.step === 'video'">
                        <media-upload title="capture_video_title"
                                      name="video"
                                      #videoComponent
                                      buttonText="start_capture"
                                      reviewTitle="{{(videoComponent?.localVideoUrl) ?'video_to_uploaded': 'video_uploaded'}}"
                                      module="video"
                                      (nextStep)="nextStep(i);"
                                      [steps]="[this.videoInspectionItemTemplate]"
                                      (videoUploaded)="handleFileChange($event,i);"
                                      subtitle="complete_video">
                            <div class="video-description">
                                <span><strong>{{'note' |translate}}:</strong>{{'capture_instruction' | translate}}</span>
                                <ul>
                                    <li>{{'capture_instruction_chassis' | translate}}
                                        <xa-button type="basic"
                                                   size="sm"
                                                   (click)="openVideoInstruction();"
                                                   class="info">
                                            {{'find_where' | translate}}
                                        </xa-button>
                                    </li>
                                    <li>{{'capture_instruction_lighting' | translate}}</li>
                                </ul>
                            </div>
                        </media-upload>
                    </div>
                </mat-step>
            </mat-stepper>
        </div>

        <div class="thankyou-section"
             *ngIf="alreadySubmitted || isSubmitted">
            <div>
                <mat-icon [svgIcon]="'case-submit-info-thank-you'"></mat-icon>
                <h5 class="mt-5 thank-you-text">{{'thank_you' | translate}}</h5>
                <p class="details">{{'submit_screen_thank_you_message' | translate}}</p>
            </div>
        </div>
    </div>
</div>