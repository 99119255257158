<div class="overlay"
     [ngClass]="{ 'lock-screen':getOrientationLock ,'and': platform.ANDROID , 'ios': platform.IOS, 'right-left':procedureStep?.toLowerCase()?.includes('right side') || procedureStep?.toLowerCase()?.includes('left side')}">
    <img #imgElement
         [src]="overlayShadow"
         *ngIf="overlayShadow"
         (error)="imgElement.style.display= 'none'"
         alt="Vehicle Outline">
    <chq-custom-loader [showLoading]="outlineLoading"></chq-custom-loader>
    <div class="span-con">
        <span *ngIf="procedureStep">{{procedureStep}}</span>
        <span class="tilt-text"
              *ngIf="platform.ANDROID || platform.IOS">{{'tilt_portrait' | translate}}</span>
    </div>

</div>