<div class="attention-dialog-container fx-col">
    <div class="header">
        <div class="close-icon">
            <mat-icon svgIcon="cross"
                      (click)="cancelDialog()"></mat-icon>
        </div>
    </div>
    <div class="content fx-col fx-1">
        <h4>{{'where_video'| translate}}</h4>
        <div class="img-wrapper">
            <div>
                <img src="assets/img/seat-info.png"
                     alt="dashboard" />
                {{'dashboard'| translate}}
            </div>

            <hr class="hr hr-right">
            <div class="vin-box">
                <div class="dot"></div>
                {{data.vin}}

                <div class="dot"></div>
            </div>
        </div>
        <div class="img-btm">
            <img src="assets/img/car-info.png"
                 alt="dashboard" />
            <hr class="hr">
            <div>
                <img class="seat-img"
                     src="assets/img/dash-examp.png"
                     alt="seat" />
                {{'side_driver_door'| translate}}
            </div>

        </div>

    </div>
</div>