<div *ngIf="showEstimateLog"
     class='d-flex align-center estimate-label'
     style='cursor: pointer;'
     (click)="hideEstimateLog()">
    <mat-icon svgIcon="left-arrow"></mat-icon><span>Back/Estimate</span>
</div>
<div class="d-flex justify-space-between align-center estimate-label"
     *ngIf="!isHideHeaders">
    <h3 *ngIf="showEstimateLog">{{ 'Estimate Log'}}</h3>
    <h3 *ngIf="isUpSalesSelected && !showEstimateLog">{{ 'Add Upsales'}}</h3>
    <h3 *ngIf="!isAiGeneratedEstimate && !showEstimateLog && !isUpSalesSelected">{{ ('estimate' | translate)}}</h3>
    <h3 class="text-blue"
        *ngIf="isAiGeneratedEstimate && !showEstimateLog && !isUpSalesSelected"><mat-icon svgIcon="ai-estimate"
                  aria-hidden="false"></mat-icon>Ai Generated Estimate</h3>
    <div class="header-action-buttons">
        <ng-container *ngIf="userIsBMOrSA && isUpsaleEditAllowedStatuses && !showEstimateLog">
            <xa-button class="up-sales-icon"
                       type="outline"
                       [color]="isUpSalesSelected ? 'primary': 'gray'"
                       [ngClass]="{'gray-up-sales': !isUpSalesSelected, 'blue-up-sales': !isUpSalesSelected}"
                       (click)="clickedUpsale()"><mat-icon svgIcon="noun-rocket"
                          aria-hidden="false"></mat-icon>Upsales</xa-button>
        </ng-container>
        <div *ngIf="!fromRepairerView && (approvalStatus === 'Pending Approval' || approvalStatus === 'Modified' || approvalStatus === 'Approved' || approvalStatus === 'Rejected') && !showEstimateLog"
             class='status-pill'
             [ngClass]="{'approved': approvalStatus === 'Approved', 'rejected': approvalStatus === 'Rejected', 'modified': approvalStatus === 'Modified', 'pending-approval': approvalStatus === 'Pending Approval'}">
            {{approvalStatus === 'Pending Approval' ? 'Pending Approval' : ''}}
            {{approvalStatus === 'Modified' ? 'Modified Estimate' : ''}}
            {{approvalStatus === 'Approved' ? 'Approved Estimate' : ''}}
            {{approvalStatus === 'Rejected' ? 'Rejected Estimate': ''}}
        </div>
        <ng-container *ngIf="hasEstimateLogPermission">
            <chq-button *ngIf="!fromRepairerView && !showEstimateLog && this.repairStatus !== 'RepairEstimation' && this.repairStatus !== 'Draft' && estimateId !== 0"
                        [buttonModel]="estimateLogModel"></chq-button>
        </ng-container>
        <ng-container *ngIf="!showEstimateLog">
            <ng-container *ngIf="estimateSource === 'GTMotive'; else manualTemplate">
                <chq-button *ngIf="estimateResponse?.estimateCharges?.length > 0"
                            [buttonModel]="repairEstimateReportModel"
                            (click)="handledownloadPdf()"></chq-button>
                <chq-button *ngIf="!isExternal && estimateResponse?.estimateCharges?.length > 0 && serviceType === 'quote'"
                            [buttonModel]="repairEstimateReportDownloadCSV"
                            (click)="handledownloadCSV()"></chq-button>
            </ng-container>
        </ng-container>
    </div>
    <ng-template #manualTemplate>
        <div class="container-selector"
             [formGroup]="formGroup">
            <xa-select class="xa-select"
                       *ngIf="estimateResponse?.estimateCharges?.length > 0"
                       formControlName="reportType"
                       [options]="dropdownOptions"
                       [config]="dropdownModelFilterConfig">
            </xa-select>
            <xa-button *ngIf="estimateResponse?.estimateCharges?.length > 0"
                       (click)="handleEstimateReport()"
                       [disabled]="selectedReport === ''">
                <mat-icon svgIcon="report-check"
                          class="icon-report"
                          aria-hidden="false"></mat-icon>
                <span> {{'generate_report' | translate }}</span>
            </xa-button>
        </div>
    </ng-template>

</div>
<div *ngIf="showEstimateLog"
     class="estimate-main-container estimate-log">
    <div class="estimate-details-block"
         *ngIf="!noDataFound">
        <div class="table-parent mt-0">
            <h6>STATUS</h6>
            <table width="100%">
                <thead>
                    <tr>
                        <td>Username</td>
                        <td>Date & Time</td>
                        <td>{{'version' | translate}}</td>
                        <td>Source</td>
                        <td>Event</td>
                        <td>Amount ({{i18n.currencySymbol}})</td>
                        <td>{{'status' | translate}}</td>
                        <td>View</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of estimateLog;let i = index">
                        <td>{{row.userName}}</td>
                        <td>{{formatDate(row.createdDate)}}</td>
                        <td>{{row.version}}</td>
                        <td>{{row.source}}</td>
                        <td>{{row.event}}</td>
                        <td>{{row.estimateAmount | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                        <td class="log-status"
                            [ngClass]="{'status-approved': row.status === 'Approved', 'status-rejected': row.status === 'Rejected' }">{{row.status}}</td>
                        <td><mat-icon (click)="viewEstimate(row.blobUrl)"
                                      [svgIcon]="'show'"></mat-icon></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div *ngIf="!showEstimateLog"
     class="estimate-main-container-wrapper"
     [ngClass]="{'left-section-ai-estimate': isAiGeneratedEstimate}">
    <div class="estimate-main-container fx-1 "
         [ngClass]="{ 'ai-estimate' :  isAiGeneratedEstimate }">
        <div class="estimate-details-block"
             *ngIf="!noDataFound">
            <h4 class="table-estimation "
                *ngIf="isAiGeneratedEstimate">Estimation</h4>
            <div [ngClass]="{'table-scroll': isAiGeneratedEstimate}">
                <div class="table-parent mt-0">
                    <h6 class="small-heading">{{'parts' | translate}}</h6>
                    <table width="100%">
                        <thead>
                            <tr>
                                <td *ngIf="isGTEstimate()"></td>
                                <td class="w-40">{{'estimate_details_description' | translate}}</td>
                                <td class="w-15">{{'code' | translate}}</td>
                                <td class="w-15">{{'units' | translate}}</td>
                                <td class="w-15">{{'price_unit' | translate}}({{i18n.currencySymbol}})</td>
                                <td class="w-15"
                                    [align]="editEstimate ? 'center' : 'right'">{{'total_price' |
                                    translate}}({{i18n.currencySymbol}})</td>
                                <td align="center"
                                    width="80px"
                                    *ngIf="editEstimate">{{'actions' | translate}}</td>
                                <ng-container *ngIf="serviceType !== 'claim'">
                                    <td *ngIf="!estimateHasRejection['partsCost']"></td>
                                    <td *ngIf="estimateHasRejection['partsCost']"
                                        align='right'>{{'notes' | translate}}</td>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of partsCost;let i = index"
                                [class.rejected-charged]="row.status === 'Rejected'? '#FEF2F2':''"
                                [ngClass]="{'child-data': row.isChildItem === true && isGTEstimate(), 'inline-edit-row': row.isEdit, 'highlight-row': activePartIds?.indexOf(row.addendaPartMasterId) !== -1}">
                                <td width="20px"
                                    *ngIf="isGTEstimate()">
                                    <span [innerHTML]="displayNotes(row)"></span>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <span *ngIf="isUpSalesSelected"><xa-checkbox [(ngModel)]="row.isUpsaleEnabled"
                                                         (ngModelChange)="onCheckBoxChange('partsCost', i, $event)"></xa-checkbox></span>
                                        <span *ngIf="row.isUpsaleEnabled && !isUpSalesSelected"><mat-icon class="upsale-rocket"
                                                      svgIcon="noun-rocket-upsale"
                                                      aria-hidden="false"></mat-icon></span>
                                        {{row.description}}<span class="asterisk-edited-entry"
                                              *ngIf="isAiGeneratedEstimate && (row.isEdited || !row.inspectionItemId)"> *</span>
                                    </div>
                                </td>
                                <td>
                                    <span *ngIf="!row.isEdit">{{row.code}}</span>
                                    <chq-input *ngIf="row.isEdit"
                                               [inputModel]="row.formModel.code"
                                               (controlOutput)="onFormUpdate($event, 'partsCost', i)"
                                               class="code-input"></chq-input>
                                </td>
                                <td class="text-light">
                                    <span *ngIf="!row.isEdit">{{row.quantity}}</span>
                                    <chq-input *ngIf="row.isEdit"
                                               [inputModel]="row.formModel.quantity"
                                               (controlOutput)="onFormUpdate($event, 'partsCost', i)"></chq-input>
                                </td>
                                <td>
                                    <span *ngIf="!row.isEdit">{{row.cost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>
                                    <chq-input *ngIf="row.isEdit"
                                               [inputModel]="row.formModel.cost"
                                               (controlOutput)="onFormUpdate($event, 'partsCost', i)"></chq-input>
                                </td>
                                <td [align]="editEstimate ? 'center' : 'right'">{{(row.totalCalculated !== undefined ? row.totalCalculated : row.total) |
                                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                <td *ngIf="editEstimate">
                                    <ng-container *ngIf="serviceType === 'claim'; else defaultAction">
                                        <div class="icon-wrapper">
                                            <chq-input [inputModel]="row.radioInputModel"
                                                       (controlOutput)="handleRadioButtonClick(row, $event)"></chq-input>
                                            <div class="comment-div notes-wrapper"
                                                 *ngIf="row?.status === 'Rejected'">
                                                <mat-icon class="comment-icon"
                                                          [svgIcon]="'comment'"
                                                          (click)="openCommentDialog(row)"></mat-icon>
                                                <div class="red-icon"
                                                     *ngIf="row?.notes"></div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #defaultAction>
                                        <div class="icon-wrapper">
                                            <mat-icon *ngIf="!row.isEdit"
                                                      class="edit-icon"
                                                      [svgIcon]="'edit'"
                                                      (click)="handleEditIconClick('partsCost', i)"></mat-icon>
                                            <mat-icon *ngIf="row.isEdit"
                                                      class="edit-icon save-icon"
                                                      [svgIcon]="'save'"
                                                      (click)="handleSaveIconClick('partsCost', i, 'part')"></mat-icon>
                                            <mat-icon *ngIf="row.isEdit"
                                                      class="edit-icon ml-0"
                                                      [svgIcon]="'cross'"
                                                      (click)="handleCancelIconClick('partsCost', i, 'part')"></mat-icon>
                                            <mat-icon class="delete-icon"
                                                      [svgIcon]="'delete'"
                                                      (click)="handleIconClick('delete', row.id)"></mat-icon>
                                        </div>
                                    </ng-template>
                                </td>
                                <ng-container *ngIf="serviceType !== 'claim'">
                                    <td *ngIf="row.status !== 'Rejected'"></td>
                                    <td *ngIf="row.status === 'Rejected'"
                                        align='right'><mat-icon (click)="openNotesDialog(row.notes)"
                                                  [svgIcon]="'comment-new'"></mat-icon></td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>

                    <table width="100%">
                        <tfoot>
                            <tr>
                                <td>{{'total_parts_cost' | translate}}</td>
                                <td class="align-right"
                                    align="right">{{(totalPartsCost) |
                                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                <td class="w-0"></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="table-parent">
                    <h6 class="small-heading">{{'labour' | translate}}</h6>
                    <table width="100%">
                        <thead>
                            <tr>
                                <td *ngIf="isGTEstimate()"></td>
                                <td class="w-40">{{'estimate_details_description' | translate}}</td>
                                <td class="w-15">{{'code' | translate}}</td>
                                <td *ngIf="isExternal">No. of Jobs</td>
                                <td class="w-15">{{'hours' | translate}}</td>
                                <td class="w-15">{{'price_unit' | translate}}({{i18n.currencySymbol}})</td>
                                <td class="w-15"
                                    [align]="editEstimate ? 'center' : 'right'">{{'total_price' |
                                    translate}}({{i18n.currencySymbol}})</td>
                                <td align="center"
                                    width="80px"
                                    *ngIf="editEstimate">{{'actions' | translate}}</td>
                                <ng-container *ngIf="serviceType !== 'claim'">
                                    <td *ngIf="!estimateHasRejection['labourCost']"></td>
                                    <td *ngIf="estimateHasRejection['labourCost']"
                                        align='right'>{{'notes' | translate}}</td>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of labourCost;let i = index"
                                [class.rejected-charged]="row.status === 'Rejected'? '#FEF2F2':''"
                                [ngClass]="{'child-data': row.isChildItem === true && isGTEstimate(), 'inline-edit-row': row.isEdit, 'highlight-row': activePartIds?.indexOf(row.addendaPartMasterId) !== -1}">
                                <td width="20px"
                                    *ngIf="isGTEstimate()">
                                    <span [innerHTML]="displayNotes(row)"></span>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <span *ngIf="isUpSalesSelected"><xa-checkbox [(ngModel)]="row.isUpsaleEnabled"
                                                         (ngModelChange)="onCheckBoxChange('labourCost', i, $event)"></xa-checkbox></span>
                                        <span *ngIf="row.isUpsaleEnabled && !isUpSalesSelected"><mat-icon class="upsale-rocket"
                                                      svgIcon="noun-rocket-upsale"
                                                      aria-hidden="false"></mat-icon></span>
                                        {{row.description}}<span class="asterisk-edited-entry"
                                              *ngIf="isAiGeneratedEstimate && (row.isEdited || !row.inspectionItemId)"> *</span>
                                    </div>
                                </td>
                                <td>
                                    <span *ngIf="!row.isEdit">{{ isExternal ? row.jobCode : row.code}}</span>
                                    <chq-input *ngIf="row.isEdit"
                                               [inputModel]="row.formModel.code"
                                               (controlOutput)="onFormUpdate($event, 'labourCost', i)"
                                               class="code-input"></chq-input>
                                </td>
                                <td *ngIf="isExternal">{{row.jobMaxCount}}</td>
                                <td class="text-light">
                                    <span *ngIf="!row.isEdit">{{row.quantity}} {{'hours' | translate}}</span>
                                    <chq-input *ngIf="row.isEdit"
                                               [inputModel]="row.formModel.quantity"
                                               (controlOutput)="onFormUpdate($event, 'labourCost', i)"></chq-input>
                                </td>
                                <td>
                                    <span *ngIf="!row.isEdit">{{row.cost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>
                                    <chq-input *ngIf="row.isEdit"
                                               [inputModel]="row.formModel.cost"
                                               (controlOutput)="onFormUpdate($event, 'labourCost', i)"></chq-input>
                                </td>
                                <td align="{{editEstimate ? 'center' : 'right'}}">{{(row.totalCalculated !== undefined ? row.totalCalculated : row.total) |
                                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                <td *ngIf="editEstimate">
                                    <ng-container *ngIf="serviceType === 'claim'; else defaultAction">
                                        <div class="icon-wrapper">
                                            <chq-input [inputModel]="row.radioInputModel"
                                                       (controlOutput)="handleRadioButtonClick(row, $event)"></chq-input>
                                            <div class="comment-div notes-wrapper"
                                                 *ngIf="row?.status === 'Rejected'">
                                                <mat-icon class="comment-icon notes-wrapper"
                                                          [svgIcon]="'comment'"
                                                          (click)="openCommentDialog(row)"></mat-icon>
                                                <div class="red-icon"
                                                     *ngIf="row?.notes"></div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #defaultAction>
                                        <div class="icon-wrapper">
                                            <mat-icon *ngIf="!row.isEdit"
                                                      class="edit-icon"
                                                      [svgIcon]="'edit'"
                                                      (click)="handleEditIconClick('labourCost', i)"></mat-icon>
                                            <mat-icon *ngIf="row.isEdit"
                                                      class="edit-icon save-icon"
                                                      [svgIcon]="'save'"
                                                      (click)="handleSaveIconClick('labourCost', i, 'part')"></mat-icon>
                                            <mat-icon *ngIf="row.isEdit"
                                                      class="edit-icon ml-0"
                                                      [svgIcon]="'cross'"
                                                      (click)="handleCancelIconClick('labourCost', i, 'part')"></mat-icon>
                                            <mat-icon class="delete-icon"
                                                      [svgIcon]="'delete'"
                                                      (click)="handleIconClick('delete', row.id)"></mat-icon>
                                        </div>
                                    </ng-template>
                                </td>
                                <ng-container *ngIf="serviceType !== 'claim'">
                                    <td *ngIf="row.status !== 'Rejected'"></td>
                                    <td *ngIf="row.status === 'Rejected'"
                                        align='right'><mat-icon (click)="openNotesDialog(row.notes)"
                                                  [svgIcon]="'comment-new'"></mat-icon></td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%">
                        <tfoot>
                            <tr>
                                <td>{{'total_labour_cost' | translate}}</td>
                                <td class="align-right"
                                    align="right">{{totalLabourCost |
                                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                <td class="w-0"></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="table-parent">
                    <h6 class="small-heading">{{'paint' | translate}}</h6>
                    <div class="paint-type small-heading"
                         *ngIf="isGTEstimate()">
                        <div>
                            {{'paint_system' | translate}}: {{ this.estimateResponse?.estimateSummary?.paintSystem }}
                        </div>
                        <div *ngIf="this.estimateResponse?.estimateSummary?.paintSystem?.toLowerCase() === 'azt'">
                            {{'paint_type' | translate}}: {{ this.estimateResponse?.estimateSummary?.paintType }}
                        </div>
                    </div>
                    <table width="100%">
                        <thead>
                            <tr>
                                <td *ngIf="isGTEstimate()"></td>
                                <td class="w-40">{{'estimate_details_description' | translate}}</td>
                                <td class="w-15">{{'code' | translate}}</td>
                                <td class="w-15">{{'unit_hours' | translate}}</td>
                                <td class="w-15"> {{'price_unit' | translate}}({{i18n.currencySymbol}})</td>
                                <td class="w-15"
                                    [align]="editEstimate ? 'center' : 'right'">{{'total_price' |
                                    translate}}({{i18n.currencySymbol}})</td>
                                <td align="center"
                                    width="80px"
                                    *ngIf="editEstimate">{{'actions' | translate}}</td>
                                <ng-container *ngIf="serviceType !== 'claim'">
                                    <td *ngIf="!estimateHasRejection['paintLabour']"></td>
                                    <td *ngIf="estimateHasRejection['paintLabour']"
                                        align='right'>{{'notes' | translate}}</td>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="paintLabour.length > 0">
                                <tr>
                                    <td class="fw-bold"
                                        [attr.colspan]="editEstimate ? '7': '6'">{{'paint_labour' | translate}}
                                    </td>
                                </tr>
                                <tr *ngFor="let row of paintLabour;let i = index;"
                                    [class.rejected-charged]="row.status === 'Rejected'? '#FEF2F2':''"
                                    [ngClass]="{'child-data': row.isChildItem === true && isGTEstimate(),'inline-edit-row': row.isEdit, 'highlight-row': activePartIds?.indexOf(row.addendaPartMasterId) !== -1}">
                                    <td width="20px"
                                        *ngIf="isGTEstimate()">
                                        <span [innerHTML]="displayNotes(row)"></span>
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <span *ngIf="isUpSalesSelected"><xa-checkbox [(ngModel)]="row.isUpsaleEnabled"
                                                             (ngModelChange)="onCheckBoxChange('paintLabour', i, $event)"></xa-checkbox></span>
                                            <span *ngIf="row.isUpsaleEnabled && !isUpSalesSelected"><mat-icon class="upsale-rocket"
                                                          svgIcon="noun-rocket-upsale"
                                                          aria-hidden="false"></mat-icon></span>
                                            {{row.description}}<span class="asterisk-edited-entry"
                                                  *ngIf="isAiGeneratedEstimate && (row.isEdited || !row.inspectionItemId)"> *</span>
                                        </div>
                                    </td>
                                    <td>
                                        <span *ngIf="!row.isEdit">{{isMGEstimate || isExternal ? row.jobCode : row.code}}</span>
                                        <chq-input *ngIf="row.isEdit"
                                                   [inputModel]="row.formModel.code"
                                                   (controlOutput)="onFormUpdate($event, 'paintLabour', i)"
                                                   class="code-input"></chq-input>
                                    </td>
                                    <td class="text-light">
                                        <span *ngIf="!row.isEdit">{{row.quantity}} h</span>
                                        <chq-input *ngIf="row.isEdit"
                                                   [inputModel]="row.formModel.quantity"
                                                   (controlOutput)="onFormUpdate($event, 'paintLabour', i)"></chq-input>
                                    </td>
                                    <td>
                                        <span *ngIf="!row.isEdit">{{row.cost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>
                                        <chq-input *ngIf="row.isEdit"
                                                   [inputModel]="row.formModel.cost"
                                                   (controlOutput)="onFormUpdate($event, 'paintLabour', i)"></chq-input>
                                    </td>
                                    <td [align]="editEstimate ? 'center' : 'right'">{{(row.totalCalculated !== undefined ? row.totalCalculated :row.total) |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                    <td *ngIf="editEstimate">
                                        <ng-container *ngIf="serviceType === 'claim'; else defaultAction">
                                            <div class="icon-wrapper">
                                                <chq-input [inputModel]="row.radioInputModel"
                                                           (controlOutput)="handleRadioButtonClick(row, $event)"></chq-input>
                                                <div class="comment-div notes-wrapper"
                                                     *ngIf="row?.status === 'Rejected'">
                                                    <mat-icon class="comment-icon notes-wrapper"
                                                              [svgIcon]="'comment'"
                                                              (click)="openCommentDialog(row)"></mat-icon>
                                                    <div class="red-icon"
                                                         *ngIf="row?.notes"></div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #defaultAction>
                                            <div class="icon-wrapper">
                                                <mat-icon *ngIf="!row.isEdit"
                                                          class="edit-icon"
                                                          [svgIcon]="'edit'"
                                                          (click)="handleEditIconClick('paintLabour', i)"></mat-icon>
                                                <mat-icon *ngIf="row.isEdit"
                                                          class="edit-icon save-icon"
                                                          [svgIcon]="'save'"
                                                          (click)="handleSaveIconClick('paintLabour', i, 'paintLabour')"></mat-icon>
                                                <mat-icon *ngIf="row.isEdit"
                                                          class="edit-icon ml-0"
                                                          [svgIcon]="'cross'"
                                                          (click)="handleCancelIconClick('paintLabour', i, 'paintLabour')"></mat-icon>
                                                <mat-icon class="delete-icon"
                                                          [svgIcon]="'delete'"
                                                          (click)="handleIconClick('delete', row.id)"></mat-icon>
                                            </div>
                                        </ng-template>
                                    </td>
                                    <ng-container *ngIf="serviceType !== 'claim'">
                                        <td *ngIf="row.status !== 'Rejected'"></td>
                                        <td *ngIf="row.status === 'Rejected'"
                                            align='right'><mat-icon (click)="openNotesDialog(row.notes)"
                                                      [svgIcon]="'comment-new'"></mat-icon></td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="paintMaterial.length > 0">
                                <tr>
                                    <td class="fw-bold"
                                        [attr.colspan]="editEstimate ? '7': '6'">{{'paint_material' |
                                        translate}}</td>
                                </tr>
                                <tr *ngFor="let row of paintMaterial;let i = index"
                                    [class.rejected-charged]="row.status === 'Rejected'"
                                    [ngClass]="{'child-data': row.isChildItem === true && isGTEstimate(), 'inline-edit-row': row.isEdit, 'highlight-row': activePartIds?.indexOf(row.addendaPartMasterId) !== -1}">
                                    <td width="20px"
                                        *ngIf="isGTEstimate()">
                                        <span [innerHTML]="displayNotes(row)"></span>
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <span *ngIf="isUpSalesSelected"><xa-checkbox [(ngModel)]="row.isUpsaleEnabled"
                                                             (ngModelChange)="onCheckBoxChange('paintMaterial', i, $event)"></xa-checkbox></span>
                                            <span *ngIf="row.isUpsaleEnabled && !isUpSalesSelected"><mat-icon class="upsale-rocket"
                                                          svgIcon="noun-rocket-upsale"
                                                          aria-hidden="false"></mat-icon></span>
                                            {{row.description}}<span class="asterisk-edited-entry"
                                                  *ngIf="isAiGeneratedEstimate && (row.isEdited || !row.inspectionItemId)"> *</span>
                                        </div>
                                    </td>
                                    <td>
                                        <span *ngIf="!row.isEdit">{{isMGEstimate || isExternal ? row.jobCode : row.code}}</span>
                                        <chq-input *ngIf="row.isEdit"
                                                   [inputModel]="row.formModel.code"
                                                   (controlOutput)="onFormUpdate($event, 'paintMaterial', i)"
                                                   class="code-input"></chq-input>
                                    </td>
                                    <td class="text-light">
                                        <span *ngIf="!row.isEdit">{{row.quantity}}</span>
                                        <chq-input *ngIf="row.isEdit"
                                                   [inputModel]="row.formModel.quantity"
                                                   (controlOutput)="onFormUpdate($event, 'paintMaterial', i)"></chq-input>
                                    </td>
                                    <td>
                                        <span *ngIf="!row.isEdit">{{row.cost | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</span>
                                        <chq-input *ngIf="row.isEdit"
                                                   [inputModel]="row.formModel.cost"
                                                   (controlOutput)="onFormUpdate($event, 'paintMaterial', i)"></chq-input>
                                    </td>
                                    <td [align]="editEstimate ? 'center' : 'right'">{{(row.totalCalculated !== undefined ? row.totalCalculated :row.total) |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                    <td *ngIf="editEstimate">
                                        <ng-container *ngIf="serviceType === 'claim'; else defaultAction">
                                            <div class="icon-wrapper">
                                                <chq-input [inputModel]="row.radioInputModel"
                                                           (controlOutput)="handleRadioButtonClick(row, $event)"></chq-input>
                                                <div class="comment-div notes-wrapper"
                                                     *ngIf="row?.status === 'Rejected'">
                                                    <mat-icon class="comment-icon notes-wrapper"
                                                              [svgIcon]="'comment'"
                                                              (click)="openCommentDialog(row)"></mat-icon>
                                                    <div class="red-icon"
                                                         *ngIf="row?.notes"></div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #defaultAction>
                                            <div class="icon-wrapper">
                                                <mat-icon *ngIf="!row.isEdit"
                                                          class="edit-icon"
                                                          [svgIcon]="'edit'"
                                                          (click)="handleEditIconClick('paintMaterial', i)"></mat-icon>
                                                <mat-icon *ngIf="row.isEdit"
                                                          class="edit-icon save-icon"
                                                          [svgIcon]="'save'"
                                                          (click)="handleSaveIconClick('paintMaterial', i, 'paintMaterial')"></mat-icon>
                                                <mat-icon *ngIf="row.isEdit"
                                                          class="edit-icon ml-0"
                                                          [svgIcon]="'cross'"
                                                          (click)="handleCancelIconClick('paintMaterial', i, 'paintMaterial')"></mat-icon>
                                                <mat-icon class="delete-icon"
                                                          [svgIcon]="'delete'"
                                                          (click)="handleIconClick('delete', row.id)"></mat-icon>
                                            </div>
                                        </ng-template>
                                    </td>
                                    <ng-container *ngIf="serviceType !== 'claim'">
                                        <td *ngIf="row.status !== 'Rejected'"></td>
                                        <td *ngIf="row.status === 'Rejected'"
                                            align='right'><mat-icon (click)="openNotesDialog(row.notes)"
                                                      [svgIcon]="'comment-new'"></mat-icon></td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <table width="100%">
                        <tfoot>
                            <tr>
                                <td>{{'total_paint_cost' | translate}}</td>
                                <td class="align-right"
                                    align="right">{{totalPaintCost |
                                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                <td class="w-0"></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="summary">
                    <div class="table-parent">
                        <h4 class="sub-heading">{{'summary' | translate}}</h4>
                        <h6 class="small-heading"> {{'parts' | translate}}</h6>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td>{{'subtotal' | translate}}</td>
                                    <td align="right">{{summary?.parts?.partsSubTotal |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                                <tr>
                                    <td>{{ (estimateSource === 'GTMotive' ? "sundry_parts" : "consumables") | translate }}</td>
                                    <td align="right">{{summary?.parts?.sundryParts |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                                <tr>
                                    <td>{{'total_before_discount' | translate}}</td>
                                    <td align="right">{{summary?.parts?.partsTotalBeforeDiscount |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                                <tr>
                                    <td>{{'discount_on_parts' | translate}}({{summary?.parts?.partsTotalDiscountPercentage}}%)
                                    </td>
                                    <td align="right">{{summary?.parts?.partsTotalDiscount |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>{{'total_parts' | translate}}</td>
                                    <td align="right">{{(+summary?.parts?.partsTotal + (+summary?.parts?.sundryParts || 0)) |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div class="table-parent">
                        <h6 class="small-heading">{{'labour' | translate}}</h6>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td>{{'labour_amount_subtotal' | translate}}</td>
                                    <td align="right">{{this.totalLabourCost |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                                <tr>
                                    <td>{{'labour_hours' | translate}}</td>
                                    <td align="right">{{summary?.labour?.labourTimeHours}} Hrs</td>
                                </tr>
                                <tr>
                                    <td>{{'estimate_details_labour_discount' |
                                        translate}}({{summary?.labour?.labourTotalDiscountPercentage}}%)</td>
                                    <td align="right">{{summary?.labour?.labourTotalDiscount |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>{{'total_labour_cost' | translate}}</td>
                                    <td align="right">{{summary?.labour?.labourTotal |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div class="table-parent">
                        <h6 class="small-heading"> {{'paint' | translate}}</h6>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td>{{'paint_labour_time' | translate}}</td>
                                    <td align="right">{{summary?.paint?.labour?.paintLabourTimeHour}} Hrs</td>
                                </tr>
                                <tr>
                                    <td>{{'paint_labour_subtotal' | translate}}</td>
                                    <td align="right">{{summary?.paint?.labour?.paintLabourSubTotal |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                                <tr>
                                    <td>{{'estimate_details_paint_labour_discount' |
                                        translate}}({{summary?.paint?.labour?.paintLabourDiscountPercentage}}%)</td>
                                    <td align="right">{{summary?.paint?.labour?.paintLabourDiscount |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                                <tr>
                                    <td class="sub-total">{{'labour_total' | translate}}</td>
                                    <td align="right">{{summary?.paint?.labour?.paintLabourTotal |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                                <tr>
                                    <td>{{'material_subtotal' | translate}}</td>
                                    <td align="right">{{summary?.paint?.material?.paintMaterialSubTotal |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                                <tr>
                                    <td>{{'estimate_details_material_discount' |
                                        translate}}({{summary?.paint?.material?.paintMateriaDiscountPercentage}}%)</td>
                                    <td align="right">{{summary?.paint?.material?.paintMaterialDiscount |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                                <tr>
                                    <td>{{'material_total' | translate}}</td>
                                    <td align="right">{{summary?.paint?.material?.paintMaterialTotal |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>{{'total_paint' | translate}}</td>
                                    <td align="right">{{summary?.paint?.paintTotal |
                                        xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div class="table-parent mb-0">
                    <table class="total-table"
                           width="100%"
                           [ngClass]="{'mb-0': !isGTEstimate()}">
                        <thead>
                            <tr>
                                <ng-container *ngIf="i18n.currencySymbol === 'Rs.'; else elseTemplate">
                                    <td>{{'total_cost_with_gst' | translate}}</td>
                                </ng-container>
                                <ng-template #elseTemplate>
                                    <td>{{'total_cost' | translate}}</td>
                                </ng-template>
                                <td align="right">{{total.total |
                                    xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                            </tr>
                            <!-- <tr>
                        <td>{{total?.vat}}% VAT</td>
                        <td align="right">{{total.vat | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                    </tr>
                    <tr>
                        <td>Total cost( Including VAT)</td>
                        <td align="right">{{total.totalWithVat | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}</td>
                    </tr> -->
                        </thead>
                    </table>
                </div>
                <div class="notes"
                     *ngIf="isGTEstimate()">
                    <div class="span-1">
                        <div class="p-10">
                            <img src="assets/img/white_flag.png" />
                            <span>{{'manual' | translate}}</span>
                        </div>
                        <div>
                            <mat-icon [svgIcon]="'approximate'"
                                      aria-hidden="false"></mat-icon>
                            <span>{{'approximate' | translate}}</span>
                        </div>
                    </div>

                    <div class="span-1">
                        <div class="p-10">
                            <img src="assets/img/gt.png" />
                            <span>{{'documented_by_gt_motive' | translate}}</span>
                        </div>
                        <div class="include">
                            <div>
                                <b> I </b>
                                <span>{{'included' | translate}}</span>
                            </div>
                            <div>
                                <b>NI</b>
                                <span>{{'not_included' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="span-1">
                        <div class="p-10">
                            <img src="assets/img/prepainted_part.png" />
                            <span>{{'prepainted_part' | translate}}</span>
                        </div>
                        <div>
                            <b>C </b>
                            <span>{{'conditioned_by' | translate}}</span>
                        </div>
                    </div>

                    <div class="span-1">
                        <div class="p-10">
                            <b>+</b>
                            <span>{{'thrown_by' | translate}}</span>
                        </div>
                        <div>
                            <b>-</b>
                            <span>{{'overlapped_by' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="estimate-details-no-data-found"
             *ngIf="noDataFound">
            <mat-icon class="no-data-svg"
                      [svgIcon]="'estimate-no-data'"></mat-icon>
            <p class="no-data-text"
               *ngIf="fromRepairerView ">
                {{ 'no_result_dashboard' | translate }}. <br />
                {{ 'no_repair_type_dashboard' | translate }}
            </p>
            <xa-button (click)="this.addEstimateClicked.emit();"
                       *ngIf="!fromRepairerView ">
                <mat-icon svgIcon="plus-active"></mat-icon>
                {{'create_estimate' | translate }}
            </xa-button>
        </div>
    </div>
    <div class="ai-estimate-repair-recommendation"
         *ngIf="isAiGeneratedEstimate">
        <ai-estimate-repair-recommendation [isDamageRecommendationsLoading]="isDamageRecommendationsLoading"
                                           [damageRecommendations]="damageRecommendations"
                                           (handleImageChange)="handleDamageAnalysisImageChange($event)">
        </ai-estimate-repair-recommendation>
    </div>
</div>
<div *ngIf="!showEstimateLog && isAiGeneratedEstimate"
     class="asterisk-description"><span class="asterisk-edited-entry">*</span> The part / operation is edited</div>
