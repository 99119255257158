export const CLAIM_STATUS = {
  'Draft': 'Draft',
  'ClaimAuthenticated': 'ClaimAuthenticated',
  'DetailsUpdated': 'DetailsUpdated',
  'UploadDocuments': 'UploadDocuments',
  'UploadPhotos': 'UploadPhotos',
  'Submitted': 'Submitted',
  'Assigned': 'Assigned',
  'ReviewInProgress': 'ReviewInProgress',
  'ClaimSubmittedForApproval': 'ClaimSubmittedForApproval',
  'AssignedBack': 'AssignedBack',
  'GarageAssignedforClaim': 'GarageAssignedforClaim',
  'GarageAssignmentRequestedRejected': 'GarageAssignmentRequestedRejected',
  'GarageAssignmentRequested': 'GarageAssignmentRequested',
  'RepairEstimation': 'RepairEstimation',
  'EstimateApproved': 'EstimateApproved',
  'EstimateRejected': 'EstimateRejected',
  'ApprovalReviewInProgress': 'ApprovalReviewInProgress',
  'AssignedBackFromReview': 'AssignedBackFromReview',
  'AssignedBackFromApproval': 'AssignedBackFromApproval',
  'InvoiceRequested': 'InvoiceRequested',
  'EstimateRequested': 'EstimateRequested',
  'Approved': 'Approved',
  'Rejected': 'Rejected',
  'RepairInitiated': 'RepairInitiated',
  'RepairInvoice': 'RepairInvoice',
  'SettlementInitiated': 'SettlementInitiated',
  'Complete': 'Completed',
  'ClaimApproved': 'Claim Approved'
}


export const STATUS_TEXT_DICT = {
  'Draft': 'Draft',
  'ClaimAuthenticated': 'Authenticated',
  'DetailsUpdated': 'Details Updated',
  'UploadDocuments': 'Upload Documents',
  'UploadPhotos': 'Upload Photos',
  'Submitted': 'Submitted',
  'Assigned': 'Assigned',
  'ReviewInProgress': 'Review in Progress',
  'ClaimSubmittedForApproval': 'Submitted for Approval',
  'ApprovalReviewInProgress': 'Approval in Progress',
  'AssignedBack': 'Assigned Back',
  'GarageAssignedforClaim': 'Garage Assigned',
  'GarageAssignmentRequestedRejected': 'Garage Rejected',
  'GarageAssignmentRequested': 'Garage Requested',
  'RepairEstimation': 'Repair Estimation',
  'EstimateRejected': 'Estimate Rejected',
  'EstimateApproved': 'Estimate Approved',
  'AssignedBackFromReview': 'Assigned Back Review',
  'AssignedBackFromApproval': 'Assigned Back Approval',
  'InvoiceRequested': 'Request Invoice',
  'EstimateRequested': 'Request Estimate',
  'Approved': 'Approved',
  'Rejected': 'Rejected',
  'RepairInitiated': 'Repair Initiated',
  'RepairInvoice': 'Repair Invoice',
  'SettlementInitiated': 'Settlement Initiated',
  'Completed': 'Completed',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'In Progress': 'In Progress'
}

export const GARAGE_REGISTRATION_STATUS = {
  'internal': 'AddendaRepair',
  'registered': 'Registered',
  'unregistered': 'Unregistered',
  'initiated': 'Initiated'
}

export const CLAIM_CUSTOMER_TYPE = {
  'CUSTOMER': 'customer',
  'SURVEYOR': 'surveyor'
}

export const INTIMATION_TYPE = {
  'FNOL': 'fnol',
  'CLAIM': 'claim'
}

export const claimSearchData = {
  'data': {
    'customers': {
      'sectionName': 'Customers',
      'totalCount': 7,
      'items': [
        {
          'firstName': 'QA QA',
          'lastName': 'Person Person',
          'name': 'QA QA Person Person',
          'phoneNumber': '+918396975219',
          'email': 'qa.handover.person@yopmail.com'
        },
        {
          'firstName': 'LLLLLLLL',
          'lastName': 'LLLLLLL',
          'name': 'LLLLLLLL LLLLLLL',
          'phoneNumber': '+918454623723',
          'email': 'llllll@yopmail.com'
        },
        {
          'firstName': 'PPPPPPP',
          'lastName': 'PPPPPP',
          'name': 'PPPPPPP PPPPPP',
          'phoneNumber': '+918684783434',
          'email': 'ppppp@yopmail.com'
        }
      ]
    },
    'vehicles': {
      'sectionName': 'Vehicles',
      'totalCount': 7,
      'items': [
        {
          'make': 'VAUXHALL',
          'model': 'CORSA C ',
          'vin': 'W0L0XCF0834028062',
          'plate': '122',
          'importInterval': '(00-06)',
          'firstName': 'QA QA',
          'lastName': 'Person Person',
          'name': 'QA QA Person Person',
          'phoneNumber': '+918396975219',
          'email': 'qa.handover.person@yopmail.com'
        },
        {
          'make': 'VAUXHALL',
          'model': 'CORSA C ',
          'vin': 'W0L0XCF0834028062',
          'plate': 'THDDD',
          'importInterval': '(00-06)',
          'firstName': 'LLLLLLLL',
          'lastName': 'LLLLLLL',
          'name': 'LLLLLLLL LLLLLLL',
          'phoneNumber': '+918454623723',
          'email': 'llllll@yopmail.com'
        },
        {
          'make': 'VAUXHALL',
          'model': 'CORSA C ',
          'vin': 'W0L0XCF0834028062',
          'plate': 'tTTT',
          'importInterval': '(00-06)',
          'firstName': 'PPPPPPP',
          'lastName': 'PPPPPP',
          'name': 'PPPPPPP PPPPPP',
          'phoneNumber': '+918684783434',
          'email': 'ppppp@yopmail.com'
        }
      ]
    },
    'claims': {
      'sectionName': 'Claims',
      'totalCount': 7,
      'items': [
        {
          'claimGuid': 'ddcf38cb-968c-4cae-8a55-a37b3dc2e06a',
          'policyNo': '00111',
          'claimNumber': '310010009',
          'id': '10008',
          'make': 'VAUXHALL',
          'model': 'CORSA C ',
          'vin': 'W0L0XCF0834028062',
          'name': 'QA QA Person Person',
          'phoneNumber': '+918396975219',
          'email': 'qa.handover.person@yopmail.com',
          'date': '2023-08-14T10:21:00.5000369Z',
          'lossLocation': 'Delhi',
          'policeReferenceNumber': '9999',
          'status': 'Draft'
        }
      ]
    }
  }

}

export const estimateDetailsData = {
  'id': 13899,
  'estimateGuid': 'ebbdfcb2-92f6-473f-981a-174b4a2c8bb5',
  'estimateNumber': '3-210014725',
  'estimationDate': '2023-12-11T03:43:47Z',
  'billingCode': '0000206000',
  'billingDesc': 'user no vq',
  'currencyCode': 'Rs.',
  'countryCode': 'IN',
  'countryName': 'INDIA',
  'modelCode': 'A1 (8X) 3D/SPORTBACK (10-14)',
  'vin': '66666666666666666',
  'registrationNumber': '26623',
  'source': 'Interface',
  'kiloMeters': 21,
  'customModel': null,
  'make': 'AUDI',
  'isBaseModel': true,
  'statusId': 2,
  'status': 'Committed',
  'excessFixed': 0,
  'excessPercentage': 0,
  'excessMaximum': 0,
  'excessMinimum': 0,
  'excessTotal': 0,
  'taxType': 'VAT',
  'taxPercentage': '18',
  'subTotal': '496',
  'otherItems': 0,
  'totalDiscount': 0,
  'totalDiscountPercentage': 0,
  'epaCharge': 0,
  'taxTotal': 0,
  'taxBase': '496',
  'totalExcludeExcess': '496',
  'total': '496',
  'createdDate': '2023-12-11T08:43:49.5385027Z',
  'updatedDate': '2023-12-13T08:17:48.8415533Z',
  'isActive': true,
  'createdUserID': 10313,
  'updatedUserID': 10313,
  'createdBy': 'testaddenda.xagroup@gmail.com',
  'modifiedBy': 'testaddenda.xagroup@gmail.com',
  'estimateReportURL': null,
  'isCaseFromRepair': true,
  'externalSource': 'GTMotive',
  'isManualEstimate': false,
  'estimateCharges': [
    {
      'id': 42973,
      'caseEstimateId': 13899,
      'code': 'GtT69610',
      'parentCode': null,
      'description': 'Check Front grille',
      'serviceType': 'Labour',
      'serviceSubType': null,
      'quantity': '2',
      'discount': 0,
      'variation': 0,
      'cost': '10',
      'total': '20',
      'operationType': 'Check',
      'operationId': null,
      'parentOperationId': null,
      'uniqueSequenceNum': 8,
      'isChildItem': null,
      'isAztPartDocumentedByGT': false,
      'isPrepainted': false,
      'isNotIncluded': false,
      'isIncluded': false,
      'isConditionedBy': false,
      'isThrownBy': false,
      'isOverlappedBy': false,
      'isManual': true,
      'isApproximate': false,
      'totalAfterDiscount': 20,
      'noteIcon': 'manual'
    },
    {
      'id': 43088,
      'caseEstimateId': 13899,
      'code': '63291900',
      'parentCode': null,
      'description': 'Remove and refit Front bumper',
      'serviceType': 'Labour',
      'serviceSubType': null,
      'quantity': '0.70',
      'discount': 0,
      'variation': 0,
      'cost': '10',
      'total': '7',
      'operationType': '',
      'operationId': '231504212',
      'parentOperationId': null,
      'uniqueSequenceNum': 5,
      'isChildItem': null,
      'isAztPartDocumentedByGT': false,
      'isPrepainted': false,
      'isNotIncluded': false,
      'isIncluded': false,
      'isConditionedBy': false,
      'isThrownBy': false,
      'isOverlappedBy': false,
      'isManual': null,
      'isApproximate': false,
      'totalAfterDiscount': 7,
      'noteIcon': ''
    },
    {
      'id': 43089,
      'caseEstimateId': 13899,
      'code': '66051950',
      'parentCode': '63291900',
      'description': 'Replace Front grille',
      'serviceType': 'Labour',
      'serviceSubType': null,
      'quantity': 0,
      'discount': 0,
      'variation': 0,
      'cost': '10',
      'total': 0,
      'operationType': 'Replace',
      'operationId': null,
      'parentOperationId': '231504212',
      'uniqueSequenceNum': 6,
      'isChildItem': true,
      'isAztPartDocumentedByGT': false,
      'isPrepainted': false,
      'isNotIncluded': false,
      'isIncluded': false,
      'isConditionedBy': false,
      'isThrownBy': true,
      'isOverlappedBy': false,
      'isManual': null,
      'isApproximate': false,
      'totalAfterDiscount': 0,
      'noteIcon': 'thrown_by'
    },
    {
      'id': 43090,
      'caseEstimateId': 13899,
      'code': '66051950',
      'parentCode': null,
      'description': 'Replace Front grille',
      'serviceType': 'Labour',
      'serviceSubType': null,
      'quantity': '0.40',
      'discount': 0,
      'variation': 0,
      'cost': '10',
      'total': '4',
      'operationType': 'Replace',
      'operationId': '231503096',
      'parentOperationId': null,
      'uniqueSequenceNum': 6,
      'isChildItem': null,
      'isAztPartDocumentedByGT': false,
      'isPrepainted': false,
      'isNotIncluded': false,
      'isIncluded': false,
      'isConditionedBy': false,
      'isThrownBy': false,
      'isOverlappedBy': false,
      'isManual': null,
      'isApproximate': false,
      'totalAfterDiscount': 4,
      'noteIcon': ''
    },
    {
      'id': 43091,
      'caseEstimateId': 13899,
      'code': '63301950',
      'parentCode': '66051950',
      'description': 'Remove and refit Front bumper moulding',
      'serviceType': 'Labour',
      'serviceSubType': null,
      'quantity': 0,
      'discount': 0,
      'variation': 0,
      'cost': '10',
      'total': 0,
      'operationType': '',
      'operationId': null,
      'parentOperationId': '231503096',
      'uniqueSequenceNum': 7,
      'isChildItem': true,
      'isAztPartDocumentedByGT': false,
      'isPrepainted': false,
      'isNotIncluded': false,
      'isIncluded': true,
      'isConditionedBy': false,
      'isThrownBy': false,
      'isOverlappedBy': false,
      'isManual': null,
      'isApproximate': false,
      'totalAfterDiscount': 0,
      'noteIcon': 'included'
    },
    {
      'id': 43092,
      'caseEstimateId': 13899,
      'code': '63291900',
      'parentCode': '66051950',
      'description': 'Remove and refit Front bumper',
      'serviceType': 'Labour',
      'serviceSubType': null,
      'quantity': 0,
      'discount': 0,
      'variation': 0,
      'cost': '10',
      'total': 0,
      'operationType': '',
      'operationId': null,
      'parentOperationId': '231503096',
      'uniqueSequenceNum': 5,
      'isChildItem': true,
      'isAztPartDocumentedByGT': false,
      'isPrepainted': false,
      'isNotIncluded': true,
      'isIncluded': false,
      'isConditionedBy': false,
      'isThrownBy': false,
      'isOverlappedBy': false,
      'isManual': null,
      'isApproximate': false,
      'totalAfterDiscount': 0,
      'noteIcon': 'not_included'
    },
    {
      'id': 42951,
      'caseEstimateId': 13899,
      'code': '8X0853651D 1QP',
      'parentCode': null,
      'description': 'Front grille - STONE GREY - New',
      'serviceType': 'Part',
      'serviceSubType': null,
      'quantity': '1',
      'discount': 0,
      'variation': 0,
      'cost': '465',
      'total': '465',
      'operationType': 'Replace',
      'operationId': null,
      'parentOperationId': null,
      'uniqueSequenceNum': 18,
      'isChildItem': null,
      'isAztPartDocumentedByGT': false,
      'isPrepainted': false,
      'isNotIncluded': false,
      'isIncluded': false,
      'isConditionedBy': false,
      'isThrownBy': false,
      'isOverlappedBy': false,
      'isManual': true,
      'isApproximate': false,
      'totalAfterDiscount': 465,
      'noteIcon': 'manual'
    }
  ],
  'estimateSummary': {
    'id': 9007,
    'caseEstimateId': 13899,
    'partsTotalID': 0,
    'partsSubTotal': '465',
    'partsTotalBeforeDiscount': '465',
    'partsTotalDiscount': 0,
    'partsTotalDiscountPercentage': 0,
    'partsTotal': '465',
    'sundryParts': 0,
    'anticorrosionMaterial': 0,
    'underbodyCoating': 0,
    'sparePartsDeliveryCharge': 0,
    'labourTimeHours': '3.10',
    'labourTotalID': 0,
    'labourAmountSubTotal': 0,
    'labourHourSubTotal': '31',
    'labourTotalBeforeDiscount': '31',
    'labourTotalDiscount': 0,
    'labourTotalDiscountPercentage': 0,
    'labourTotal': '31',
    'paintLabourTimeHour': 0,
    'paintLabourTotalID': 0,
    'paintLabourAmountSubTotal': 0,
    'paintLabourHourSubTotal': 0,
    'paintLabourSubTotal': 0,
    'paintLabourDiscount': 0,
    'paintLabourDiscountPercentage': 0,
    'paintLabourTotal': 0,
    'paintMaterialTotalID': 0,
    'paintMaterialSubTotal': 0,
    'paintMaterialDiscount': 0,
    'paintMateriaDiscountPercentage': 0,
    'paintMaterialTotal': 0,
    'paintTotalBeforeDiscount': 0,
    'paintDiscount': 0,
    'paintSubTotal': 0,
    'paintTotal': 0,
    'paintSystem': 'AZT',
    'paintType': '2-Coat',
    'scratchProofClearCoat': 'No',
    'multiColorPaint': 'No',
    'colourMixing': 'Yes',
    'colourSample': 'Yes',
    'maskingParts': 'No',
    'paintMaterialIndex': '100',
    'paintingConditions': 'On vehicle, not pre-painted',
    'createdDate': '2023-12-11T10:01:50.1647494Z',
    'updatedDate': '2023-12-13T08:17:48.5965555Z',
    'isActive': true,
    'createdUserID': 0,
    'updatedUserID': 10313,
    'createdBy': null,
    'modifiedBy': 'testaddenda.xagroup@gmail.com'
  },
  'estimateDocumentTypes': null
}

export const CLAIMS_SETTINGS_TYPE = {
  'GARAGE': 'Garage',
  'TOTAL_LOSS_SETTINGS': 'TotalLossSettings',
  'RECOVERY_SETTINGS': 'RecoverySettings'
}

export const GARAGE_LIST_RADIUS = {
  initial: 30,
  extended: 200
}

export const REPAIRER_VIEW_DASHBOARD_SUB_TYPES = {
  'ClaimRepairOrdersTiles': 'ClaimRepairOrdersTiles',
  'ClaimRecoveryOwnDamage': 'ClaimRecoveryOwnDamage',
  'ClaimRepairOrders': 'ClaimRepairOrders',
  'AverageRepairCost': 'AverageRepairCost',
  'TotalSales': 'TotalSales' 
}

export const REPAIRER_VIEW_DASHBOARD_TABS = {
  'General': 'General',
  'Customers': 'Customers',
  'SalesAndOperations': 'SalesAndOperations'
}

export const PERSIST_FILTER_KEYS = {
  'CLAIM_WORKFLOW': 'CLAIM_WORKFLOW',
  'CLAIM_LIST_ACTIVE_TAB': 'CLAIM_LIST_ACTIVE_TAB'
}

export const CLAIM_USER_ROLES = {
  'claimManager': 'Claim Manager'
}

export const GARAGE_REGISTRATION_STATUS_TEXT_DIC = {
  'AddendaRepair': 'Partner',
  'Registered': 'Registered',
  'Unregistered': 'Unregistered',
  'Initiated': 'Initiated'
}

export const OPERATION_TYPE_LIST = {
  'NotApplicable': 'Not Applicable',
  'Both': 'Both',
  'AgencyRepair': 'Agency Repair',
  'NonAgencyRepair': 'Non Agency Repair'
}